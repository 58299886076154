import {
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  REQUESTED_VERIFICATION_EMAIL,
  STARTED_VERIFY_EMAIL,
  AUTH_FAILED,
  RESET_PASSWORD_SUCCESS,
  STARTED_PASSWORD_RESET,
  RESET_PASSWORD_ERROR,
  STARTED_LOAD_ACCOUNTS,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_ERROR,
  CLEAR_AUTH_ERROR_MESSAGE,
  THIRD_PARTY_AUTH_FAILED,
  LINK_ACCOUNT_ERROR
} from "./types";
import {
  verifyEmail,
  passwordSignin,
  requestEmailVerification,
  resetPassword,
  isLoggedIn,
  passwordSignup,
  getConnectedAccounts,
  thirdPartySignin,
  connectThirdPartyAccount,
  disconnectThirdPartyAccount
} from "../../../backend/Auth";
import ReactGA from "react-ga";
import { doRedirect } from "../../../0-base/routing";

export const userLogin = resp => ({
  type: AUTH_LOGGED_IN,
  payload: {
    isLoggedIn: resp.data.isLoggedIn,
    userId: resp.data.userId,
    isAdmin: resp.data.isAdmin,
    emails: resp.data.emails
  }
});

export const doLoadAuthState = () => async dispatch => {
  isLoggedIn()
    .then(resp => {
      dispatch(userLogin(resp));
      sessionStorage.setItem("user_id", resp.data.userId);
    })
    .catch();
};

export const doClearAuthError = () => ({
  type: CLEAR_AUTH_ERROR_MESSAGE
});

export const doThirdPartyLogin = (accountType, token) => async dispatch => {
  thirdPartySignin(accountType, token)
    .then(userResponse => {
      setGoogleAnalytics({ userId: userResponse.data.userId });
      setActiveCampaign({ email: userResponse.data.emails[0] });
      dispatch(userLogin(userResponse));
      dispatch(doRedirect());
    })
    .catch(err => {
      console.log(err.error);
      dispatch({
        type: THIRD_PARTY_AUTH_FAILED,
        payload: err.error
      });
    });
};

export const doPasswordSignup = (email, name, password) => async dispatch => {
  passwordSignup({ name, email, password })
    .then(r => {
      setGoogleAnalytics({ userId: r.data.userId });
      setActiveCampaign({ email: r.data.emails[0] });
      console.log("password signup", r);
      dispatch(userLogin(r));
      dispatch(doRedirect());
    })
    .catch(err => {
      dispatch({ type: AUTH_FAILED, payload: err.error });
    });
};

export const doPasswordLogin = (email, password) => async dispatch => {
  passwordSignin(email, password)
    .then(userResponse => {
      setGoogleAnalytics({ userId: userResponse.data.userId });
      setActiveCampaign({ email: userResponse.data.emails[0] });
      dispatch(userLogin(userResponse));
      dispatch(doRedirect());
    })
    .catch(err => {
      dispatch({ type: AUTH_FAILED, payload: err.error });
    });
};

export const doLogout = () => ({
  type: AUTH_LOGGED_OUT
});

export const doRequestVerificationEmail = email => {
  requestEmailVerification(email);
  return { type: REQUESTED_VERIFICATION_EMAIL, payload: { email } };
};

export const doVerifyEmail = token => dispatch => {
  verifyEmail(token)
    .then(r => dispatch({ type: EMAIL_VERIFICATION_SUCCESS, payload: r.data }))
    .catch(r => dispatch({ type: EMAIL_VERIFICATION_ERROR, payload: r }));
  dispatch({ type: STARTED_VERIFY_EMAIL, payload: { token } });
};

export const doResetPassword = (token, password) => dispatch => {
  resetPassword(token, password)
    .then(r => dispatch({ type: RESET_PASSWORD_SUCCESS, payload: r.data }))
    .catch(r => dispatch({ type: RESET_PASSWORD_ERROR, payload: r }));
  dispatch({ type: STARTED_PASSWORD_RESET, payload: { token } });
};

export const doLoadConnectedAccounts = () => async dispatch => {
  getConnectedAccounts()
    .then(resp => {
      dispatch({
        type: LOAD_ACCOUNTS_SUCCESS,
        payload: resp.data.connectedAccounts
      });
    })
    .catch(dispatch({ type: LOAD_ACCOUNTS_ERROR }));
  dispatch({ type: STARTED_LOAD_ACCOUNTS });
};

export const doConnectThirdPartyAccount = (
  accountType,
  token
) => async dispatch => {
  connectThirdPartyAccount(accountType, token)
    .then(() => {
      dispatch(doLoadConnectedAccounts());
    })
    .catch(err => {
      dispatch({ type: LINK_ACCOUNT_ERROR, payload: err.error });
    });
};

export const doDisconnectThirdPartyAccount = accountType => async dispatch => {
  disconnectThirdPartyAccount(accountType)
    .then(() => {
      dispatch(doLoadConnectedAccounts());
    })
    .catch(err => {
      dispatch({ type: LINK_ACCOUNT_ERROR, payload: err.error });
    });
};

const setGoogleAnalytics = ({ userId }) => {
  ReactGA.set({ userId });
};

const setActiveCampaign = ({ email }) => {
  // if (process.env.REACT_APP_BUILD_ENV !== "development") {
  //   window.vgo("setEmail", email);
  // }
};
