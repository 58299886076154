import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import ReactGA from "react-ga";
import Router from "./routes";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

// Initialize google analytics
const debug = process.env.REACT_APP_BUILD_ENV === "development";
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
  debug
});

export default function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}
