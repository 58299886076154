import React from "react";
import styled from "@emotion/styled";
// import { connect } from "react-redux";

// const select = ({ auth }) => ({
//   userId: auth.user.userId
// });
function NotFoundPage(/* {userId} */) {
  let homeLink = "/";

  return (
    <Wrapper>
      <Center>
        Uh oh! The page you were looking for does not seem to exist...
      </Center>
      <Home onClick={() => (window.location.href = homeLink)}>
        Back to Home Page
      </Home>
    </Wrapper>
  );
}

export default NotFoundPage;

const Wrapper = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  font-size: 2em;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Center = styled.div`
  text-align: center;
  margin: 1em;
`;

const Home = styled.button`
  background-color: var(--primary);
  color: var(--primary-contrast);
  border: 1px solid var(--primary-contrast);
  padding: 0.75em 1.5em;
  border-radius: 4px;

  font-size: 20px;
  font-family: Roboto, arial, sans-serif;

  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: var(--primary-active);
  }

  &:focus {
    outline: none;
  }
`;
