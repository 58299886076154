import React, { useState, useEffect } from "react";
import {
  doThirdPartyLogin,
  doConnectThirdPartyAccount
} from "../auth/ducks/actions";
import { connect } from "react-redux";
import SignInButton from "./SignInButton";

const actions = {
  doLogin: doThirdPartyLogin,
  doLink: doConnectThirdPartyAccount
};

function GoogleSignin({
  doLogin,
  doLink,
  btnText,
  connect,
  logInOnly,
  ...props
}) {
  const [isEnabled, setEnabled] = useState(false);
  useEffect(() => {
    // init google auth client
    window.gapi.load("auth2", () => {
      setEnabled(true);
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        scope: "email profile"
        //hosted_domain: "umich.edu"
      });
    });
  });

  const signIn = e => {
    if (e) {
      e.preventDefault();
    }
    if (!isEnabled) return;

    const auth2 = window.gapi.auth2.getAuthInstance();

    // auth2.signIn can accept options or can use options specified in auth2.init
    auth2.signIn().then(res => onSuccess(res), err => onFailure(err));
  };

  // For reference, some of the stuff you can get from a successful response
  // const basicProfile = res.getBasicProfile();
  // const authResponse = res.getAuthResponse();
  // res.googleId = basicProfile.getId();
  // res.tokenObj = authResponse;
  // res.tokenId = authResponse.id_token;
  // res.accessToken = authResponse.access_token;
  // res.profileObj = {
  //   googleId: basicProfile.getId(),
  //   imageUrl: basicProfile.getImageUrl(),
  //   email: basicProfile.getEmail(),
  //   name: basicProfile.getName(),
  //   givenName: basicProfile.getGivenName(),
  //   familyName: basicProfile.getFamilyName()
  // };
  // this.props.onSuccess(res);

  const onSuccess = googleUser => {
    const token = googleUser.getAuthResponse().id_token;
    if (connect) doLink("Google", token);
    else doLogin("Google", token);
  };

  const onFailure = err => {
    console.log("Failed to login", err);
  };

  return (
    <SignInButton
      {...props}
      onClick={signIn}
      iconAlt="Google Logo"
      iconSrc="/assets/google_icon.svg"
      btnText={btnText}
    />
  );
}

export default connect(
  null,
  actions
)(GoogleSignin);
