/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

// Used to wrap around other inputs to attach the input class
const Input = ({ children, value, ...props }) => {
  return (
    <InputContainer className="input" value={value ? value : ""} {...props}>
      {children}
    </InputContainer>
  );
};

export default Input;

const InputContainer = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px none inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  input:focus + label {
    color: var(--active) !important;
    transition: 0.2s ease;
  }

  input,
  textarea {
    margin: 0.5em 0;
    caret-color: var(--active);
    font-size: 20px;
    outline: none;
    border: none;
    color: var(--black-text-on-white);
    border-radius: 4px;
    width: calc(100% - 1em);
    &:hover {
      cursor: pointer;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  textarea {
    background-color: white;
    border: 1px solid rgb(204, 204, 204);
    padding: 0.5em;
    font-size: 16px;
    // border-bottom: 3px solid var(--input-text-bg-color);
    transition: 0.2s ease;
    &:focus {
      border-bottom-color: var(--active) !important;
      color: var(--active);
      transition: 0.2s ease;
    }
  }

  // Specific stylings
  input[type="submit"] {
    background-color: var(--primary);
    color: white; // border-bottom: 3px solid currentColor;
    padding: 1em;
    width: 100%;
    transition: 0.2s ease;
    border: var(--lpanel-border);
    border-radius: var(--lpanel-border-radius);
    &:hover {
      color: var(--primary);
      background-color: white;
      border-color: var(--primary);
      transition: 0.2s ease;
    }
  }
`;
