import React from "react";
import styled from "@emotion/styled";
import { Link } from "../../../0-base/routing";
import { groupings } from "./NavBottomData";

export function NavBottom() {
  return (
    <Container>
      {groupings.map((grouping, idx) => (
        <PageGroupWithLabel key={idx} {...grouping} />
      ))}
    </Container>
  );
}

function PageGroupWithLabel({ title, items }) {
  return (
    <PageGroup>
      <Label>{title}</Label>
      {items.map((linkData) => getLinkToExternalOrInternalPage(linkData))}
    </PageGroup>
  );
}

function getLinkToExternalOrInternalPage(page) {
  let linkJSX = page.isExternal
    ? getExternalLinkJSX(page)
    : getInternalLinkJSX(page);
  return linkJSX;
}

function getInternalLinkJSX(internalLink) {
  return (
    <InternalItem key={internalLink.text} to={internalLink.href}>
      {internalLink.text}
    </InternalItem>
  );
}

function getExternalLinkJSX(externalLink) {
  return (
    <ExternalItem key={externalLink.text} href={externalLink.href}>
      {externalLink.text}
    </ExternalItem>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center; // Center children horizontally
  width: 100%;
  /* display: none; */
  flex-wrap: wrap;
  box-sizing: border-box;
  /* justify-content: center; */
  padding: 0 2em;
  background-color: #212121;
  border-top: 1px solid #404040;
  color: white;
  z-index: -1; // So it appears behind modals
  gap: 200px;
`;

const InternalItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.1em;
  margin-bottom: 0.1em;
`;

const ExternalItem = styled.a`
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.1em;
  margin-bottom: 0.1em;
`;

const PageGroup = styled.div`
  /* min-width: 150px; */
  padding-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

const Label = styled(Link)`
  display: inline-block;
  padding-bottom: 0.2em;
  padding-right: 1px;
  margin-bottom: 5px;
  text-decoration: none;
  font-weight: bold;
  color: white;
  &:hover {
    color: currentColor;
    cursor: default;
  }
`;
