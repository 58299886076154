/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { FontAwesomeIcon as FontAwesomeIconBase } from "@fortawesome/react-fontawesome";
import {
  faStar as faStarSolid,
  faCheck,
  faBookmark as faBookmarkSolid,
  faSearch,
  faPlusCircle as faPlus,
  faTimes,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import {
  faStar,
  faBookmark,
  faEnvelope
} from "@fortawesome/free-regular-svg-icons";

export const AddIcon = props => (
  <IconWithTextAndStyling icon={faPlus} {...props} />
);

export const EditIcon = props => (
  <IconWithTextAndStyling icon={faEdit} {...props} />
);

export const CloseIcon = props => (
  <IconWithTextAndStyling icon={faTimes} {...props} />
);

export function FavoriteIcon({ /*filled,*/ ...props }) {
  return (
    <IconWithTextAndStyling icon={faStar} filledIcon={faStarSolid} {...props} />
  );
}

export function BookmarkIcon(props) {
  return (
    <IconWithTextAndStyling
      icon={faBookmark}
      filledIcon={faBookmarkSolid}
      {...props}
    />
  );
}

export function MailIcon(props) {
  return <IconWithTextAndStyling icon={faEnvelope} {...props} />;
}

export function ExpandIcon(props) {
  return <IconWithTextAndStyling {...props} icon={faEnvelope} />;
}

export function CheckIcon(props) {
  return <IconWithTextAndStyling {...props} icon={faCheck} />;
}

export function SearchIcon(props) {
  return <IconWithTextAndStyling {...props} icon={faSearch} />;
}

// Helper component that adds common styling, text, and filled/unfilled behavior
const IconWithTextAndStyling = ({
  icon,
  filledIcon,
  filled,
  children,
  onClick,
  props
}) => {
  return (
    <StyledIcon clickable={onClick != null} onClick={onClick} {...props}>
      {icon ? <FontAwesomeIcon icon={filled ? filledIcon : icon} /> : null}
      {children}
    </StyledIcon>
  );
};

const FontAwesomeIcon = styled(FontAwesomeIconBase)`
  margin-right: 0.3em;
`;

const StyledIcon = styled.span`
  color: var(--icon);
  margin-left: 0.5em;
  margin-right: 0.5em;

  ${props =>
    props.clickable
      ? `
    transition: 0.2s ease;
    cursor: pointer;
    &:hover {
      color: var(--icon-active);
    }
  `
      : ``}
`;

export const adderCss = css`
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 10;
`;

export const logoUrl = "/assets/leaf.png";
