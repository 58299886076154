import React from "react";
import { RecruiterRedirect } from "./RecruiterRedirect";
import styled from "@emotion/styled";

export function AboutThera() {
  return (
    <Container>
      <RecruiterRedirect />
      <h1>About Thera</h1>
      <p>
        Thera is an AI-powered, browser-based Integrated Development Environment (IDE) dedicated to transforming the landscape of bioinformatics.{" "}
      </p>
      <p>
        Founded by bioinformatics and machine learning experts, our platform eliminates the need for complex setup processes and enables instant, collaborative coding.
      </p>{" "}
      <p>
        Our long-term vision at Thera extends even further. We're striving towards a future where complex biological systems can be simulated and understood in-silico. Through Thera, we're not only revolutionizing bioinformatics, but we're also redefining our interaction and understanding of biology itself.
      </p>
    </Container>
  );
}

const Container = styled.div`
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    background: url("/img/typing.svg") center no-repeat;
  }
  &:after {
    content: "";
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(82, 109, 145, 0.7);
  }
  // background: rgba(0, 0, 0, 0);
  position: relative;
  padding: 4em 0;
  @media (min-width: 800px) {
    padding-right: 7vw;
  }

  > h1,
  > p {
    color: white;
    padding-left: 40vw;
    padding-right: 20px;
    @media (max-width: 800px) {
      padding: 30px;
    }
  }
  > h1 {
    margin-block-start: 0;
    padding-top: 1.5em;
    padding-bottom: 0;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
  }
  > p {
    margin: 0;
    margin-bottom: 1em;
    @media (max-width: 800px) {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
  }
`;
