import React from "react";
import styled from "@emotion/styled";

export const Label2 = styled.span`
  ${(props) => props.bold && `font-weight: bold;`}
  display: block;
  font-size: 16px;
  margin-top: 0.75em;
  margin-bottom: 0.25em;
`;

const InputBase = ({ value, type, ...others }) => (
  <input {...others} value={value ? value : ""} type={type ? type : "text"} />
);

export const Input = styled(InputBase)`
  display: block;
  font-size: 14px;
  outline: none;
  color: var(--primary);
  font-family: avenir, nunito;

  border: var(--input-border);
  ${(props) => props.iserror === 1 && "border-color: var(--error-red);"}

  border-radius: var(--input-border-radius);
  margin-top: 0;
  margin-bottom: 0;

  padding: 0.6em 0.7em;
  width: calc(100% - 1.4em - 2px);

  &:focus {
    border-color: var(--primary);
  }

  // Turn off number input increment arrows
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
