import {
  // respond,
  // error_handle,
  simpleGet,
  simplePost,
  simplePatch,
  formDataPost,
  simpleDelete
} from "../BackendHelpers";
import { logout } from "../Auth";

// Check a user's role
export function getUserRole() {
  return sessionStorage.getItem("user_role");
}

export function getCurrentUserId() {
  return parseInt(sessionStorage.getItem("user_id"));
}

const studentRoles = ["undergrad", "undergraduate", "Undergraduate Student"];

export function isStudent() {
  const role = getUserRole();
  return studentRoles.includes(role);
}

export function isFaculty() {
  const role = getUserRole();
  return role != null && !studentRoles.includes(role);
}

export function hasApprovalPower() {
  return sessionStorage.getItem("user_has_approval_power");
}

// Tracks what frontend version the user has used
export function getLastSeenVersion() {
  return sessionStorage.getItem("lastSeenVersion");
}

export function setLastSeenVersion(lastSeenVersion) {
  sessionStorage.setItem("lastSeenVersion", lastSeenVersion);
  return simplePatch({
    path: "lastSeenVersion",
    data: { lastSeenVersion }
  });
}

export async function getUser({ user_id }) {
  return simpleGet({ path: "users/" + user_id });
}

// Gets the user profile without additional info
export async function getUserProfile({ user_id }) {
  return simpleGet({ path: "users/" + user_id + "/profile" });
}

// DEPRECATED on 2/5/19
// Gets a user with all profile information
// export async function getUserProfileFull({ user_id }) {
//   return axios
//     .get('users/' + user_id)
//     .then(response => {
//       let user = response.data
//       return axios
//         .get('users/' + user_id + '/profiles')
//         .then(response2 => {
//           let profile = response2.data
//           return getModelHasMany({
//             path: '/profiles/' + profile.id,
//             fields: [
//               'experiences',
//               'degrees',
//               'links',
//               'certifications',
//               'honors',
//             ]
//           })
//             .then(response => {
//               user.profile = response
//               return user
//             })
//         })
//     })
// }

// RESTRICTED: user_id
// AUTHZ: is user
export async function deleteUser() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleDelete({ path: "users/" + user_id + "/delete" })
    .then(() => logout())
    .catch();
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function updateUser({ user }) {
  let user_id = sessionStorage.getItem("user_id");
  // user._method = 'PUT'
  return simplePatch({
    path: "users/" + user_id,
    data: user
  });
}

// Universal Profile Update
// Input should be in format
/*
profile: {
  role: "string",
  contactEmail: "string",
  contactPhone: "string,"
  bio: "string", 

  // The following are arrays of attached objects
  // Include an id if you want to update existing object (check getUserProfile)
  // Don't include id if you want to create a new object
  // Leave an empty array to delete all objects
  // Don't include the array at all (null) to leave things unchanged

  // NOTE: to generate date string, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toJSON

  experiences : [
    {
      id: 0,
      type: "string", (REQUIRED)
      title: "string", (REQUIRED)
      description: "string",
      startDate: "dateString",
      endDate:  "dateString"
    }
  ]

  certifications: [
    {
      id: 0,
      name: "string", (REQUIRED)
    }
  ]

  degrees: [
    {
      id: 0,
      degree: "string", (REQUIRED)
      field: "string", (REQUIRED)
      location: "string", (REQUIRED)
      startDate: "dateString", (REQUIRED)
      endDate: "dateString", (REQUIRED)
    }
  ]

  honors: [
    {
      id: 0,
      name: "string", (REQUIRED)
      date: "string",
    }
  ]

  links: [
    {
      id: 0,
      type: "string", (REQUIRED)
      link: "string",
    }
  ]
}
*/

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// WARNING: This function will soon only update fields directly on the profile object
//          E.g. name, role, email, phone, bio
export async function updateUserProfile(profile) {
  let user_id = sessionStorage.getItem("user_id");
  if (profile.role) {
    sessionStorage.setItem("user_role", profile.role);
  }
  // delete profile.id
  return simplePost({
    path: "users/" + user_id + "/profile/sync",
    data: profile
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserGroups() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({ path: "users/" + user_id + "/groups" });
}

/*
  File functions (profile pictures and resumes)
  Currently you can only have one of each; uploading a new one will delete the old one 
*/

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getProfileResumeFile() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "/users/" + user_id + "/profile/resume"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getProfilePictureFile(user_id) {
  // let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "/public/users/" + user_id + "/profile/profile_picture"
  });
}

/*
To upload a file:
HTML: 
  <input type="file" name="fileToUpload" id="fileToUpload"></input>
Javascript:
  let file = document.getElementById('fileToUpload').files[0];
  uploadUserResumeDocument({user_id: 1, file: file});
*/

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function uploadResumeFile({ file }) {
  let user_id = sessionStorage.getItem("user_id");
  return formDataPost({
    path: "users/" + user_id + "/profile/resume",
    data: file
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function uploadProfilePictureFile({ file }) {
  let user_id = sessionStorage.getItem("user_id");
  return formDataPost({
    path: "users/" + user_id + "/profile/profile_picture",
    data: file
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteResumeFile() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleDelete({
    path: "users/" + user_id + "/profile/resume"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteProfilePictureFile() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleDelete({
    path: "users/" + user_id + "/profile/profile_picture"
  });
}

// Certification functions //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  id: 0,
  name: "string", (REQUIRED)
}
*/
export async function createUserProfileCertification(certification) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/certifications",
    data: certification
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileCertifications() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/certifications"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileCertification(certification) {
  // let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "certifications/" + certification.id,
    data: certification
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileCertifications({ certifications }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/certifications/sync",
    data: { certifications }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileCertification(certification_id) {
  // let user_id = sessionStorage.getItem("user_id");
  return simpleDelete({
    path: "certifications/" + certification_id
  });
}

// Experience functions //

// export async function syncUserProfileExperiences({ experiences }) {
//   let user_id = sessionStorage.getItem("user_id");
//   return simplePatch({
//     path: "users/" + user_id + "/profile/experiences",
//     data: { experiences }
//   });
// }

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  type: "string", (REQUIRED)
  title: "string", (REQUIRED)
  description: "string",
  startDate: "dateString",
  endDate:  "dateString"
}
*/
export async function createUserProfileExperience(experience) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/experiences",
    data: experience
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileExperiences() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/experiences"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileExperience(experience) {
  // let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "experiences/" + experience.id,
    data: experience
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileExperiences({ experiences }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/experiences/sync",
    data: { experiences }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileExperience(experience_id) {
  // let user_id = sessionStorage.getItem("user_id");
  return simpleDelete({
    path: "experiences/" + experience_id
  });
}

// Degree functions //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  degree: "string", (REQUIRED) should be something like Bachelors
  field: "string", (REQUIRED)
  location: "string", (REQUIRED)
  university: "string", (REQUIRED)
  startDate: "dateString", (REQUIRED)
  endDate: "dateString", (REQUIRED)
}
*/
export async function createUserProfileDegree(degree) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/degrees",
    data: degree
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileDegrees() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/degrees"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileDegree(degree) {
  return simplePatch({
    path: "degrees/" + degree.id,
    data: degree
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileDegrees({ degrees }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/degrees/sync",
    data: { degrees }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileDegree(degree_id) {
  // let user_id = sessionStorage.getItem("user_id");
  return simpleDelete({
    path: "degrees/" + degree_id
  });
}

// Honor functions //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  name: "string", (REQUIRED)
  date: "string",
}
*/
export async function createUserProfileHonor(honor) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/honors",
    data: honor
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileHonors() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/honors"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileHonor(honor) {
  return simplePatch({
    path: "honors/" + honor.id,
    data: honor
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileHonors({ honors }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/honors/sync",
    data: { honors }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileHonor(honor_id) {
  return simpleDelete({
    path: "honors/" + honor_id
  });
}

// Link functions //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  type: "string", (REQUIRED)
  link: "string",
}
*/
export async function createUserProfileLink(link) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/links",
    data: link
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileLinks() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/links"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileLink(link) {
  return simplePatch({
    path: "links/" + link.id,
    data: link
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileLinks({ links }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/links/sync",
    data: { links }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileLink(link_id) {
  return simpleDelete({
    path: "links/" + link_id
  });
}

// Publications //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  authors: "string", (REQUIRED)
  title: "string", (REQUIRED)
  publication: "string", (REQUIRED)
  year: "string", (REQUIRED)
  link: "string",
}
*/
export async function createUserProfilePublication(publication) {
  let user_id = sessionStorage.getItem("user_id");
  console.log("create pub", publication);
  console.log("year type", typeof publication.year);
  return simplePost({
    path: "users/" + user_id + "/profile/publications",
    data: publication
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfilePublications() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/publications"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfilePublication(publication) {
  return simplePatch({
    path: "publications/" + publication.id,
    data: publication
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfilePublications({ publications }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/publications/sync",
    data: { publications }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfilePublication(publication_id) {
  return simpleDelete({
    path: "publications/" + publication_id
  });
}

// References //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  referencer_name: "string", (REQUIRED)
  referencer_title: "string",
  text: "string", (REQUIRED)
}
*/
export async function createUserProfileReference(reference) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/references",
    data: reference
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileReferences() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/references"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileReference(reference) {
  return simplePatch({
    path: "references/" + reference.id,
    data: reference
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileReferences({ references }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/references/sync",
    data: { references }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileReference(reference_id) {
  return simpleDelete({
    path: "reference/" + reference_id
  });
}

// Courses //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  name: "string", (REQUIRED)
}
*/
export async function createUserProfileCourse(course) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/courses",
    data: course
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileCourses() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/courses"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileCourse(course) {
  return simplePatch({
    path: "courses/" + course.id,
    data: course
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileCourses({ courses }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/courses/sync",
    data: { courses }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileCourse(course_id) {
  return simpleDelete({
    path: "course/" + course_id
  });
}

// Languages //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
/*
{
  name: "string", (REQUIRED)
  proficiency: "string",
}
*/
export async function createUserProfileLanguage(language) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/languages",
    data: language
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function getUserProfileLanguages() {
  let user_id = sessionStorage.getItem("user_id");
  return simpleGet({
    path: "users/" + user_id + "/profile/languages"
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
// Include id in object
export async function updateUserProfileLanguage(language) {
  return simplePatch({
    path: "languages/" + language.id,
    data: language
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileLanguages({ languages }) {
  let user_id = sessionStorage.getItem("user_id");
  return simplePatch({
    path: "users/" + user_id + "/profile/languages/sync",
    data: { languages }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function deleteUserProfileLanguage(language_id) {
  return simpleDelete({
    path: "language/" + language_id
  });
}

// Skill and Interest functions //

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileInterests({ interests }) {
  const user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/interests",
    data: { interests }
  });
}

// RESTRICTED: user_id from sessionStorage
// AUTHZ: is user
export async function syncUserProfileSkills({ skills }) {
  const user_id = sessionStorage.getItem("user_id");
  return simplePost({
    path: "users/" + user_id + "/profile/skills",
    data: { skills }
  });
}
