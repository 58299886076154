import React from "react";
import { SixFeatureLayout } from "../common/SixFeatureLayout";

const info = [
  {
    header: "Accelerated Onboarding.",
    description: "Our cloud-based IDE enables bioinformaticians to dive into coding instantly.",
    imgSrc: "/img/homepage/ide/1.svg"
  },
  {
    header: "AI-Driven Coding.",
    description:
      "Thera simplifies the coding process with intelligent code suggestions and automated error detection.",
    imgSrc: "/img/homepage/ide/2.svg"
  },
  {
    header: "Data Interpretation.",
    description:
      "Turn raw files into comprehensible insights, and making data interpretation a breeze.",
    imgSrc: "/img/homepage/ide/3.svg"
  },
  {
    header: "Seamless Collaboration.",
    description:
      "Supports real-time collaboration. Work together on projects, share insights, and learn from each other.",
    imgSrc: "/img/homepage/ide/4.svg"
  },
  {
    header: "In-depth Learning Aid.",
    description:
      "The AI assistance helps beginners understand bioinformatics coding and analysis.",
    imgSrc: "/img/homepage/ide/5.svg"
  },
  {
    header: "Secure and Reliable.",
    description:
      "Robust security measures are in place to protect your data.",
    imgSrc: "/img/homepage/ide/6.svg"
  }
];

export function KeyBenefits() {
  return <SixFeatureLayout items={info} />;
}
