import React from "react";
import styled from "@emotion/styled";
import { H4 as H4Base, H3 } from "../../../3-utils/Common";
import { Button } from "../../../3-utils/Buttons";
import { LabelledInput, TextArea } from "../../../3-utils/Inputs";

const questions = [
  { q: "Name", type: null, backendName: "name" },
  { q: "Email", type: null, backendName: "email" },
  { q: "What questions do you have for us?", type: "textarea" },
  { q: "Are there any additional features you would like?", type: "textarea" },
  { q: "Is there anything else you would like us to know?", type: "textarea" }
];

export default function Feedback() {
  return (
    <Page>
      <H3>Contact the developers!</H3>
      {questions.map((q, idx) => (
        <div key={idx}>
          <H4>{q.q}</H4>
          {q.type === "textarea" ? (
            <TextArea
              type="text"
              defaultValue=""
            />
          ) : (
            <LabelledInput
              type="text"
              defaultValue=""
            />
          )}
        </div>
      ))}
      <Button>Submit</Button>
    </Page>
  );
}

const H4 = styled(H4Base)`
  margin-block-end: 0;
`;

const Page = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2em auto;
  max-width: 500px;
  box-sizing: border-box;

  > div {
    width: 100%;
  }
`;
