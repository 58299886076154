import React from "react";
import styled from "@emotion/styled";
import { LabelledInput } from "../../../../../3-utils/Inputs";
import { RoundButton } from "../../../../../3-utils/Buttons";

function Search() {
  const searchPlaceholderText = "Want to learn more? Enter email here...";
  const isMobile = window.innerWidth < 800;

  return (
    <Container>
      <Title>
        A <b>new way</b> for biologists to interface with their data
      </Title>

      {!isMobile && (
        <InputWrapper>
          <LabelledInput
            placeholder={searchPlaceholderText}
            value=""
            readOnly
            style={{
              padding: "12px",
              paddingLeft: "16px",
              fontSize: "18px",
              borderRadius: "16px",
              borderColor: "#424242",
            }}
          />

          <RoundButton style={{ marginLeft: "12px" }}>
            Join the waitlist
          </RoundButton>
        </InputWrapper>
      )}
    </Container>
  );
}

export default Search;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -40px;
`;

const Title = styled.h1`
  margin-block-start: 0;
  font-family: avenir, nunito;
  /* display: none; */
  font-weight: lighter;
  line-height: 1.2em;
  font-size: 36px;
  /* font-weight: bold; */
  position: relative;
  padding: 48px 0;
  @media (max-width: 800px) {
    font-size: 30px;
  }

  &::before {
    /* content: "Don’t settle for less. Study what you love."; */
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    letter-spacing: 0.005em;
    top: 0;
    transform: translateY(-100%);
    @media (max-width: 800px) {
      font-size: 14px;
      transform: translateY(-100%);
    }
  }
`;


const InputWrapper = styled.div`
  position: relative;
  max-width: 725px;
  display: flex;
  > div {
    flex: 1;
    margin: 0 8px;
  }

  transform-origin: top left;
`;

