import { invertArray } from "../4-helpers";

// This file contains the intified pivot fields on the backend,
// mostly role, relation, etc.
//
// This is necessary to keep strings off of pivots but avoid
// additional key tables for performance
//
// WARNING: This file must be kept updated with the corresponding
//          fields and keys on the backend

// Projects
export const ProjectJobTypes = [
  "Full-time",
  "Part-time",
  "Temporary",
  "Contract",
  "Internship",
  "Commission",
  "Credit",
  "Volunteer"
];
export const ProjectJobTypesStrToInt = invertArray(ProjectJobTypes);

// GroupUserPivot
export const GroupUserPivotRoleIntToStr = [
  "owner", // Only one user per group may be owner
  "admin",
  "member",
  "requested",
  "invited",
  "declined",
  "rejected"
];
export const GroupUserPivotRoleStrToInt = invertArray(
  GroupUserPivotRoleIntToStr
);

export const groupAdmin = ["owner", "admin"].map(
  str => GroupUserPivotRoleStrToInt[str]
);
export const groupMembers = ["owner", "admin", "member"].map(
  str => GroupUserPivotRoleStrToInt[str]
);
export const groupEditors = ["owner", "admin"].map(
  str => GroupUserPivotRoleStrToInt[str]
);
export const groupDeleters = ["owner"].map(
  str => GroupUserPivotRoleStrToInt[str]
);

// ProjectUserPivot
export const ProjectUserPivotRoleIntToStr = ["owner", "admin", "member"];
export const ProjectUserPivotRoleStrToInt = invertArray(
  ProjectUserPivotRoleIntToStr
);

export const projectEditors = ["owner", "admin"].map(
  str => ProjectUserPivotRoleStrToInt[str]
);
export const projectDeleters = ["owner", "admin"].map(
  str => ProjectUserPivotRoleStrToInt[str]
);
console.log(ProjectUserPivotRoleStrToInt);

// EventUserPivot
export const EventUserPivotRoleIntToStr = ["owner", "invited", "accepted"];
export const EventUserPivotRoleStrToInt = invertArray(
  EventUserPivotRoleIntToStr
);

export const eventEditors = ["owner"].map(
  str => EventUserPivotRoleStrToInt[str]
);
export const eventDeleters = ["owner"].map(
  str => EventUserPivotRoleStrToInt[str]
);
console.log(EventUserPivotRoleStrToInt);
