import React from "react";
import { SixFeatureLayout } from "../common/SixFeatureLayout";

const data = [
  {
    header: "Seamless ML Integration.",
    description:
      "Leverage specialized AI and ML models designed to integrate seamlessly into your existing biotech workflows, enabling more efficient data processing and insightful analysis.",
    imgSrc: "/img/homepage/ml/first.svg"
  },
  {
    header: "Biology-Focused Models.",
    description:
      "Access a curated repository of biology-centric ML models, developed and maintained by experienced engineers with backgrounds in academia and industry, to meet your specific biological use case.",
    imgSrc: "/img/homepage/ml/second.svg"
  },
  {
    header: "Secure Cloud-Based Testing",
    description:
      "Experiment with multiple models in the cloud using sample datasets to identify the best model for your needs, while maintaining full data security and confidentiality.",
    imgSrc: "/img/homepage/ml/third.svg"
  },
  {
    header: "Custom Deployment Flexibility",
    description:
      "Deploy the chosen model effortlessly on your own infrastructure, ensuring data control, and compatibility with your established systems and protocols.",
    imgSrc: "/img/homepage/ml/fourth.svg"
  },
  {
    header: "Training and Deployment of Custom Models",
    description:
      "Our platform supports end-to-end ML solutions, enabling scientists to train and deploy their custom models on their own infrastructure.",
    imgSrc: "/img/homepage/ml/fifth.svg"
  },
  {
    header: "Built on Trusted Open-Source Frameworks.",
    description:
      "We build on popular open-source frameworks like KubeFlow and Ray Serve, facilitating easy creation and deployment of ML apps/APIs via Docker containers.",
    imgSrc: "/img/homepage/ml/sixth.svg"
  }
];

export function KeyBenefits() {
  return <SixFeatureLayout items={data} />;
}
