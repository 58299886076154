import {
  STARTED_ERROR_LOGS_FETCHING,
  FINISHED_ERROR_LOGS_FETCHING,
  STARTED_SERVER_LOGS_FETCHING,
  FINISHED_SERVER_LOGS_FETCHING,
  STARTED_SQL_LOGS_FETCHING,
  FINISHED_SQL_LOGS_FETCHING
} from "./types";

const initialState = {
  isFetchingServer: false,
  isFetchingError: false,
  isFetchingSql: false,
  serverResult: [],
  errorResult: [],
  sqlResult: []
};

export default function facultySearchReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STARTED_SERVER_LOGS_FETCHING:
      return { ...state, isFetchingServer: true };
    case FINISHED_SERVER_LOGS_FETCHING:
      return {
        ...state,
        isFetchingServer: false,
        serverResult: payload.results.logs
      };
    case STARTED_ERROR_LOGS_FETCHING:
      return { ...state, isFetchingError: true };
    case FINISHED_ERROR_LOGS_FETCHING:
      return {
        ...state,
        isFetchingError: false,
        errorResult: payload.results.logs
      };
    case STARTED_SQL_LOGS_FETCHING:
      return { ...state, isFetchingSql: true };
    case FINISHED_SQL_LOGS_FETCHING:
      return {
        ...state,
        isFetchingSql: false,
        sqlResult: payload.results.logs
      };
    default:
      return state;
  }
}
