import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { doPasswordLogin, doClearAuthError } from "../../auth/ducks/actions";
import { LabelledInput } from "../../../3-utils/Inputs";
import { RoundButton } from "../../../3-utils/Buttons";
import styled from "@emotion/styled";
import { Modal } from "../../../3-utils/Modal";
import { forgotPassword } from "../../../backend";
import { Link } from "../../../0-base/routing";

const actions = {
  login: doPasswordLogin,
  clearErrors: doClearAuthError
};

const select = ({ auth }) => ({
  authError: auth.user.authError
});

const Login = ({ login, authError, clearErrors }) => {
  useEffect(() => {
    clearErrors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [submitted, setSubbmited] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const handleSubmit = () => {
    setSubbmited(true);
    if (email && password) {
      login({ email, password });
    }
  };

  return (
    <Container>
      {authError && <ErrorMessage>{authError}</ErrorMessage>}
      <LoginForm>
        <LabelledInput
          label="Email"
          error={submitted && !email}
          errorMsg="Email is required"
          placeholder="Email"
          value={email}
          onEnter={handleSubmit}
          onChange={e => {
            setEmail(e.target.value);
          }}
        />

        <LabelledInput
          label="Password"
          error={submitted && !password}
          errorMsg="Password is required"
          placeholder="Password"
          type="password"
          value={password}
          onEnter={handleSubmit}
          onChange={e => {
            setPassword(e.target.value);
          }}
        />

        <LinkDiv>
          <Link onClick={toggleModal}>Forgot password?</Link>
        </LinkDiv>
        <LoginButton onClick={handleSubmit}>Login</LoginButton>
        <CreateAccountText>
          {"Don't have an account? "}
          <Link to="/account/signup">{"Create one."}</Link>
        </CreateAccountText>
      </LoginForm>
      <Modal
        title="Forgot Password?"
        showModal={showModal}
        closeModal={toggleModal}
      >
        <ResetPassword onClose={toggleModal} />
      </Modal>
    </Container>
  );
};

const ResetPassword = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleForgetPassword = () => {
    if (email) {
      forgotPassword(email);
      setSubmitted(true);
    }
  };

  return (
    <div>
      {!submitted && (
        <div>
          <LabelledInput
            label="Email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            onEnter={handleForgetPassword}
          />
          <RoundButton onClick={handleForgetPassword}>Submit</RoundButton>
        </div>
      )}
      {submitted && (
        <div>
          <p>
            An email has been sent to the supplied email address. Follow the
            instructions in the email to reset your password.
          </p>
          <RoundButton
            onClick={() => {
              onClose();
            }}
          >
            Close
          </RoundButton>
        </div>
      )}
    </div>
  );
};

export default connect(
  select,
  actions
)(Login);

const Container = styled.div`
  text-align: center;
  color: var(--black-text-on-white);
`;

const LoginForm = styled.div`
  text-align: left;
`;

const LoginButton = styled(RoundButton)`
  width: 100%;
`;

const LinkDiv = styled.div`
  text-align: right;
  margin: -1em 0 1em 0;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1em;
`;

const CreateAccountText = styled.div`
  text-align: center;
  margin-bottom: 0.5em;
`;
