export const groupings = [
  {
    title: "Home",
    href: "/",
    items: [
      { text: "About", href: "/about" },
      { text: "BioMLTasks", href: "/hire" }
    ]
  },
  {
    title: "News",
    href: "",
    items: [
      {
        text: "Blog",
        href: "https://musingsonbiology.substack.com/?r=1ul9io&utm_campaign=pub-share-checklist",
        isExternal: true
      }
    ]
  },
  {
    title: "Contact Us",
    href: "/contact-us",
    items: [
      { text: "Feedback", href: "/feedback" },
      { text: "Join Us", href: "" }
    ]
  },
  {
    title: "Legal",
    href: "",
    items: [{ text: "Terms of Service", href: "" }]
  }
];
