import React, { useState } from "react";
import Select from "react-select";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Label2 } from "./Input";

export const Dropdown = props => {
  return <Select styles={customSelectStyles} {...props} />;
};

export const LabelledDropdown = ({
  label,
  closeMenuWhenInputIsEmpty,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Closes the menu if the input string is empty
  const handleInputChange = (s, action) => {
    switch (action.action) {
      case "menu-close":
        if (menuIsOpen) setMenuIsOpen(false);
        break;
      case "input-change":
        if (s != null && s !== "" && !menuIsOpen) {
          setMenuIsOpen(true);
        }
        if (s === "" && menuIsOpen) {
          setMenuIsOpen(false);
        }
        break;
      default:
        return;
    }
  };

  if (
    closeMenuWhenInputIsEmpty &&
    (props.onInputChange || props.menuIsOpen) != null
  ) {
    console.error(
      "Invalid props to LabelledDropdown. closeMenuWhenInputIsEmpty will override supplied menuIsOpen and onInputChange. You can implement closeMenuWhenInputIsEmpty yourself in your onInputChange function."
    );
  }

  return (
    <Container>
      <Label2>{label}</Label2>
      <Dropdown
        onInputChange={
          closeMenuWhenInputIsEmpty ? handleInputChange : undefined
        }
        menuIsOpen={closeMenuWhenInputIsEmpty ? menuIsOpen : undefined}
        {...props}
      />
    </Container>
  );
};

const Container = styled.div``;

const customSelectStyles = {
  control: (provided /*, state */) => ({
    ...provided,
    border: "2px solid #c0c0c0",
    fontSize: "14px"
    // padding: "0.6em 0.7em"
  })
};

LabelledDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMsg: PropTypes.string
};
