/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Component } from "react";
import PropTypes from "prop-types";

/* TEXT */
export const H1 = styled.h1`
  font-size: 50px;
`;

export const H2 = styled.h1`
  font-size: 30px;
`;

export const H3 = styled.h1`
  font-size: 24px;
`;

export const H4 = styled.h1`
  font-size: 20px;
`;

export const P = styled.p`
  font-size: 16px;
  color: grey;
`;

/* POSITIONING */

// Floats a single panel right in the middle of the nearest relative parent
export const Floater = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

/* MODAL */
export class BasicModal extends Component {
  render() {
    let { children, onClose, ...rest } = this.props;
    return (
      <Container onClick={onClose} {...rest}>
        <Floater onClick={e => e.stopPropagation()}>{children}</Floater>
      </Container>
    );
  }
}

BasicModal.propTypes = {
  // text for the submit button
  submitText: PropTypes.string,
  // what to do when submitted
  onSubmit: PropTypes.func,
  // what goes inside the modal container
  children: PropTypes.object.isRequired,
  // title of the modal
  title: PropTypes.string.isRequired,
  // boolean for whether to show the modal
  showModal: PropTypes.bool.isRequired,
  // func that should flip the showModal bool
  onClose: PropTypes.func.isRequired
};

// conditionally shows modal depending on state of parent component
const Container = styled.div`
  display: ${props => (props.showModal ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => (props.showModal ? "1000" : "-1")};
  height: 100vh;
  width: 100vw;
  opacity: ${props => (props.showModal ? "1" : "0")};
  transition: 0.2s ease;
  overflow: hidden;
  animation: 0.2s fadeIn ease;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
