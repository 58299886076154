import {
  respond,
  simpleGet,
  simplePost,
  recaptchaPost
} from "./BackendHelpers.js";
import { getUserProfile } from "./";
// import { UserAgentApplication } from "msal";

/**
 * Password routes
 */

export async function passwordSignup({ name, email, password }) {
  return recaptchaPost({
    path: "/auth/signup",
    data: { email, name, password }
  });
}

export async function passwordSignin({ email, password }) {
  return recaptchaPost({
    path: "/auth/signin",
    data: { email, password }
  }).then(response => {
    return storeUser(response);
  });
}

export function forgotPassword(email) {
  recaptchaPost({ path: "/auth/forgotPassword", data: { email } });
}

export function verifyEmail(token) {
  return simplePost({ path: "/auth/verifyEmail", data: { token } });
}

export function requestEmailVerification(email) {
  return recaptchaPost({
    path: "auth/requestEmailVerification",
    data: { email }
  });
}

export function resetPassword(token, password) {
  return simplePost({ path: "/auth/resetPassword", data: { token, password } });
}

/**
 * Third party routes
 */

/* Login and Signup are the same for third party flow
 *
 * Returns an object stating whether:
 * - the user needs to choose a role
 * - the user needs to go through the initial profile building process
 */
export async function thirdPartySignin(accountType, token) {
  return recaptchaPost({
    path: "/auth/" + accountType.toLowerCase(),
    data: { token }
  }).then(response => {
    return storeUser(response);
  });
}

export function getConnectedAccounts() {
  return simpleGet({ path: "/auth/connectedAccounts" });
}

export function connectThirdPartyAccount(accountType, token) {
  return simplePost({
    path: "/auth/" + accountType.toLowerCase() + "/link",
    data: { token }
  });
}

export function disconnectThirdPartyAccount(accountType) {
  return simpleGet({ path: "/auth/" + accountType.toLowerCase() + "/unlink" });
}

/**
 * Common routes
 */

export function isLoggedIn() {
  return simpleGet({ path: "/auth/isLoggedIn" });
}

export async function logout() {
  let tid = await localStorage.getItem("tid");
  // Clear everything
  sessionStorage.clear();
  localStorage.clear();

  try {
    await window.gapi.auth2.getAuthInstance().signOut();
  } catch (err) {
    console.log(err);
  }
  await simpleGet({ path: "/auth/logout" });
  console.log(tid);
  if (tid) {
    window.location.href =
      "https://login.microsoftonline.com/" +
      tid +
      "/oauth2/v2.0/logout?post_logout_redirect_uri=" +
      process.env.REACT_APP_URL;

  } else {
    window.location.href = "/";
  }
}

/**
 * Helper functions
 */

export function canEditProfile({ userId }) {
  if (sessionStorage.getItem("user_id") === userId) {
    return true;
  }
  return false;
}

async function storeUser(response) {
  sessionStorage.setItem("user_id", response.data.userId);
  sessionStorage.setItem("lastSeenVersion", response.data.lastSeenVersion);
  let profileResponse = await getUserProfile({
    user_id: response.data.userId
  });
  let profile = profileResponse.data;
  if (profile && profile.role) {
    sessionStorage.setItem("user_role", profile.role);
  }
  return respond({
    status: response.status,
    data: response.data
  });
}
