import React, { useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "../../../0-base/routing";

export default function MobileNav({ items }) {
  const [showSlidingSidePanel, setShowSlidingSidePanel] = useState(false);
  return (
    <Container>
      <HamburgerIconWrapper>
        <HamburgerIcon onClick={openSidePanel} />
      </HamburgerIconWrapper>

      <SlidingPanel hide={!showSlidingSidePanel}>
        <CloseIcon onClick={closeSidePanel}>&times;</CloseIcon>
        {items.map(({ href, text }) => (
          <NavItem to={href} key={href} onClick={closeSidePanel}>
            {text}
          </NavItem>
        ))}
      </SlidingPanel>
    </Container>
  );

  function openSidePanel() {
    setShowSlidingSidePanel(true);
  }

  function closeSidePanel() {
    setShowSlidingSidePanel(false);
  }
}

const Container = styled.div`
  height: 100%;
`;

const HamburgerIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SlidingPanel = styled.div`
  position: fixed;
  background-color: var(--primary);
  top: 0;
  right: 0;
  height: 100vh;
  transition: 0.2s ease;
  transform: translateX(${({ hide }) => (hide ? "110%" : "0%")});
  ${({ hide }) => !hide && "box-shadow: -5px 0 5px rgba(0, 0, 0, 0.3)"};
`;

const NavItem = styled(Link)`
  color: white;
  display: block;
  font-size: 20px;
  padding: 0.5em 2em;
`;

const CloseIcon = styled.div`
  color: white;
  display: block;
  font-size: 30px;
  padding-left: 10px;
  padding-top: 5px;
`;

const HamburgerIcon = props => (
  <FontAwesomeIcon
    icon={faBars}
    {...props}
    style={{ color: "rgba(0,0,0,0.5)", fontSize: "16px" }}
  />
);
