import React from "react";
import { logoUrl } from "../../../3-utils/Icons";
import styled from "@emotion/styled";

export function Mission() {
  return (
    <Container>
      <Icon src={logoUrl} />
      <Text>
        The {" "}<b>future</b> of biology is <b>human</b> + <b>AI</b>
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 10em;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 6em 4em;
  }
`;

const Text = styled.div`
  font-size: 32px;
  text-align: center;
  padding-top: 1em;
  max-width: 1100px;
`;

const Icon = styled.img`
  height: 50px;
`;
