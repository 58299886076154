import {
  BROWSER_CHANGED_URL,
  UPDATE_ROUTING_PARAMS,
  REQUESTED_NAVIGATE_TO,
  PUSH_REDIRECT,
  POP_REDIRECT
} from "./types";

const initialState = {
  pathname:
    typeof window.location !== undefined ? window.location.pathname : "/",
  redirectStack: []
};

export default function routingReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case BROWSER_CHANGED_URL: {
      const { pathname } = payload;
      if (pathname === state.pathname) return state;
      // Update the redux pathname to match the browser's
      return { ...state, pathname };
    }
    case UPDATE_ROUTING_PARAMS: {
      const { params } = payload;
      return { ...state, params };
    }
    case REQUESTED_NAVIGATE_TO: {
      const { pathname /*replace*/ } = payload;
      return { ...state, pathname };
    }
    case PUSH_REDIRECT: {
      let redirectStack = [...state.redirectStack, payload];
      return { ...state, redirectStack };
    }
    case POP_REDIRECT: {
      const num = payload;
      let redirectStack = state.redirectStack.slice(
        0,
        state.redirectStack.length - num
      );
      return { ...state, redirectStack };
    }
    default:
      return state;
  }
}
