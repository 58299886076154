export const AUTH_LOGGED_IN = "AUTH_LOGGED_IN";
export const AUTH_LOGGED_OUT = "AUTH_LOGGED_OUT";
export const THIRD_PARTY_AUTH_FAILED = "THIRD_PARTY_AUTH_FAILED";
export const AUTH_FAILED = "AUTH_LOGIN_FAILED";
export const CLEAR_AUTH_ERROR_MESSAGE = "CLEAR_AUTH_ERROR_MESSAGE";

export const REQUESTED_VERIFICATION_EMAIL = "REQUESTED_VERIFICATION_EMAIL";
export const STARTED_VERIFY_EMAIL = "STARTED_VERIFY_EMAIL";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";

export const STARTED_PASSWORD_RESET = "STARTED_PASSWORD_RESET";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const STARTED_LOAD_ACCOUNTS = "STARTED_LOAD_ACCOUNTS";
export const LOAD_ACCOUNTS_SUCCESS = "LOAD_ACCOUNTS_SUCCESS";
export const LOAD_ACCOUNTS_ERROR = "LOAD_ACCOUNTS_ERROR";

export const LINK_ACCOUNT_ERROR = "LINK_ACCOUNT_ERROR";
