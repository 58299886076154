import "./0-base/general.scss";

import React, { useEffect, useState } from "react";
import {
  doBrowserChangedUrl,
  doUpdateRoutingParams,
} from "./0-base/routing/ducks";

import About from "./2-modules/staticPages/about/About";
import ApplicantLandingPage from "./2-modules/staticPages/landing/applicants/ApplicantLandingPage";
import Feedback from "./2-modules/staticPages/feedback/Feedback";
import LoginPage from "./2-modules/staticPages/login/LoginPage";
import NavBarContainer from "./2-modules/staticPages/navbar/NavBarContainer";
import { NavBottom } from "./2-modules/staticPages/navbottom/NavBottom";
import NotFoundPage from "./2-modules/staticPages/NotFoundPage";
import RecruiterLandingPage from "./2-modules/staticPages/landing/recruiters/RecruiterLandingPage";
import { connect } from "react-redux";
import createMatcher from "feather-route-matcher";
import { doLoadAuthState } from "./2-modules/auth/ducks";
import styled from "@emotion/styled";

const routes = [
  { path: "/about", component: About },
  { path: "/account/*", component: LoginPage },
  { path: "/feedback", component: Feedback },
  { path: "/hire", component: RecruiterLandingPage },
  // 404 and Homepage must be last
  { path: "/", component: ApplicantLandingPage },
  { path: "/*", component: NotFoundPage },
];

const select = ({ routing, auth }) => ({
  routing: routing,
  loggedIn: auth.user.isLoggedIn,
  adminStatus: auth.user.isAdmin,
});

const actions = {
  updateReduxRoute: doBrowserChangedUrl,
  updateRoutingParams: doUpdateRoutingParams,
  loadAuthState: doLoadAuthState,
};

function Router({
  routing,
  loggedIn,
  adminStatus,

  loadAuthState,
  updateReduxRoute,
  updateRoutingParams,
}) {
  const [routeInfo, setRouteInfo] = useState(null);
  const { pathname } = routing;

  useEffect(() => {
    loadAuthState();
  }, [loadAuthState]);

  // Update redux if we navigate via browser back/forward button
  useEffect(() => {
    window.addEventListener("popstate", () => {
      updateReduxRoute(window.location.pathname);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function parseRouteInfo() {
      /* feather-route-matcher requires an object of the form
       *
       * { 'path1': Component, 'path2': Component2, ...}
       *
       * This checks dev, loggedIn permissions and builds the routes object
       * to supply to createMatcher
       *
       * TODO: try not to rebuild createMatcher unless necessary (isLoggedIn changes)
       * this can be tricky because createMatcher returns the same function id so
       * to react it looks like the state isn't changing.
       */
      let newObj = routes.reduce((routeObj, route) => {
        if (
          route.dev &&
          process.env.REACT_APP_BUILD_ENV !== "development" &&
          process.env.REACT_APP_BUILD_ENV !== "dev-server"
        )
          return routeObj;
        if (route.loggedIn && !loggedIn) return routeObj;
        if (route.admin && !adminStatus) return routeObj;

        routeObj[route.path] = route.component;
        return routeObj;
      }, {});

      const cm = createMatcher(newObj);
      return cm(pathname);
    }

    const rinfo = parseRouteInfo();
    if (!rinfo) {
      console.log("invalid route: ", pathname);
      return;
    } else {
      console.log("valid route: ", pathname);
    }
    setRouteInfo(rinfo);
    console.log("rinfo", rinfo);
    updateRoutingParams(rinfo.params);
  }, [pathname, setRouteInfo, updateRoutingParams, adminStatus, loggedIn]);

  const RouteComponent = routeInfo ? routeInfo.page : null;
  const params = routeInfo ? routeInfo.params : [];
  const url = routeInfo ? routeInfo.url : null;

  return (
    <div>
      <NavBarContainer />
      <ContentContainer id="mainScrollArea">
        {routeInfo && (
          <RouteComponent
            /* for backwards compatibility, mimic match.params */
            /* these are the same params as in Redux routing.params */
            match={{ params, url }}
          />
        )}
        <NavBottom />
      </ContentContainer>
    </div>
  );
}

export default connect(
  select,
  actions
)(Router);

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100vh - var(--nav-total-height));
  // overflow: scroll;
`;
