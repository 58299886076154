import { combineReducers } from "redux";
// import { applicantSearchReducer } from "./2-modules/searches/ducks";
// import { projectReducer } from "./2-modules/searches/projectSearch";
// import { facultySearchReducer } from "./2-modules/searches/facultySearch";
// import { activeProfileReducer } from "./2-modules/user";
// import { groupsReducer } from "./2-modules/groups";
// import { onboardingReducer } from "./2-modules/onboarding/ducks";
import { routingReducer } from "./0-base/routing/ducks";
// import { createProjectReducer } from "./2-modules/project/create-project/ducks";
// import { createGroupReducer } from "./2-modules/groups/create-group/ducks";
import { authReducer } from "./2-modules/auth/ducks";
import { logReducer } from "./2-modules/server/ducks";
// import { messagesReducer } from "./2-modules/messages/ducks";
// import { recaptchaReducer } from "./2-modules/recaptcha/ducks";
import errorModalReducer from "./2-modules/errorModal/ducks/reducers";
// import projectManagerReducer from "./2-modules/project/CreatedProjectsManager/ducks/reducers";
// import mobileSearchReducer from "./2-modules/searches/MobileSearch/ducks/reducers";

export default combineReducers({
  // applicantSearch: applicantSearchReducer,
  // projectSearch: projectReducer,
  // facultySearch: facultySearchReducer,
  // activeProfile: activeProfileReducer,
  // groups: groupsReducer,
  //onboarding: onboardingReducer,
  routing: routingReducer,
  // createProject: createProjectReducer,
  // createGroup: createGroupReducer,
  auth: authReducer,
  logs: logReducer,
  // messages: messagesReducer,
  // recaptcha: recaptchaReducer,
  errorModal: errorModalReducer,
  // projectManager: projectManagerReducer,
  // mobileSearch: mobileSearchReducer
});
