import React from "react";
import { doNavigateTo } from "./ducks";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const actions = {
  navigateTo: doNavigateTo
};

// Tried to copy react-router-dom Link component as much as possible
const Link = ({
  to,
  onClick,
  target,
  replace,
  resetScroll,
  navigateTo,
  children,
  ...others
}) => {
  const handleClick = event => {
    try {
      if (onClick) onClick(event);
    } catch (ex) {
      event.preventDefault();
      throw ex;
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === "_self") && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      navigateTo(to, { replace, resetScroll });
    }
  };

  const href = to;

  return (
    <A {...others} onClick={handleClick} href={href}>
      {children}
    </A>
  );
};

export default connect(
  null,
  actions
)(Link);

const A = styled.a`
  text-decoration: none;
  &:hover {
    color: var(--primary-active);
  }
`;
