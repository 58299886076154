import styled from "@emotion/styled";

export const SmallButton = styled.button`
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 4px;
  padding: 0.5em;

  color: white;
  font-size: 14px;
  font-family: Raleway, arial, sans-serif;

  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    color: var(--primary);
    background-color: white;
  }

  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  ${(props) =>
    props.backgroundColor
      ? `
    background-color: var(--${props.backgroundColor});
    color: var(--${props.backgroundColor}-contrast);
    border: 1px solid var(--${props.backgroundColor}-contrast);
  `
      : `
    background-color: var(--primary);
    color: var(--primary-contrast);
    border: 1px solid var(--primary-contrast);
  `}
  padding: 0.5em 1.5em;
  border-radius: 4px;

  font-size: 18px;
  font-family: "Open Sans", arial, sans-serif;

  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? "var(--" + props.backgroundColor + "-active)"
        : "var(--primary-active)"};
  }

  &:focus {
    outline: none;
  }
`;

export const RoundButton = styled(Button)`
  border-radius: 9999px;
  font-weight: bold;
`;

export const SquareButton = styled.div`
  padding: 12px;
  border: 1px solid var(--primary);
  color: var(--primary);
  text-transform: uppercase;
  margin: 12px 0;
  background: white;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
`;
