import React from "react";
import styled from "@emotion/styled";

export function WhyThera() {
  return (
    <Container>
      <Elaboration>
        Thera is a web-based IDE tailored for bioinformatics.
        Accelerate data analysis in an editor built for <u>AI-assisted collaboration</u>.
      </Elaboration>
    </Container>
  );
}

const Container = styled.div`
  background-color: #4579c3;
  color: white;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  padding: 48px;
  b {
    color: white;
  }
  @media (max-width: 800px) {
    padding: 2em;
  }
`;

// const Title = styled.h1`
//   font-size: 28px;
//   font-weight: bold;
//   font-style: normal;
//   margin-bottom: 0.5em;
// `;

const Elaboration = styled.p`
  margin-top: 0;
  font-size: 25px;
  font-weight: lighter;
  line-height: 1.8em;
  font-style: normal;
  /* text-align: center; */
  margin: 0;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`;
