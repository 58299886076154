import React from "react";
import styled from "@emotion/styled";
import { Button } from "../../../../3-utils/Buttons";

export function MainGraphic() {
  return (
    <Container>
      <Graphic />
      <TextOverlay />
    </Container>
  );

  function TextOverlay() {
    return (
      <TextContainer>
        <Blurb>
          <h1>Machine Learning Simplified for Bio</h1>
          <p>
            Discover, test, and deploy the right ML model for your research, all in one place
          </p>
          <JobButton>
            Explore
          </JobButton>
        </Blurb>
      </TextContainer>
    );
  }
}

const Container = styled.div`
  display: grid;
  background-color: rgba(69, 121, 195, 1);
  height: calc(85vh - var(--nav-total-height));
  min-height: 700px;
  grid-template: "left right" 100% / 40% 60%;
  padding: 0;
  @media (max-width: 800px) {
    min-height: 500px;
    height: calc(80vh - var(--nav-total-height));
    grid-template: "right" 30% "left" 70% / 100%;
  }
`;

const Graphic = styled.div`
  grid-area: right;
  position: relative;
  background: url("/img/homepage/ml/dna.png") no-repeat
    rgba(69, 121, 195, 1);
  background-size: cover;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0%;
    top: 0;
    height: 100%;
    width: 100%;
    @media (min-width: 800px) {
      background: right center no-repeat,
        linear-gradient(to right, rgba(69, 121, 195, 1), rgba(69, 121, 195, 0));
    }
    @media (max-width: 800px) {
      background: right center no-repeat,
        linear-gradient(
          to top,
          rgba(69, 121, 195, 1),
          rgba(69, 121, 195, 0),
          rgba(69, 121, 195, 0)
        );
    }
  }
`;

const TextContainer = styled.div`
  grid-area: left;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1400px) {
    justify-content: center;
  }
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
  max-width: 480px;
  text-align: left;
  font-family: Open Sans;
  font-style: normal;
  color: white;
  h1 {
    margin-block-end: 0;
    font-weight: bold;
    font-size: 48px;
    @media (max-width: 1435px) {
      font-size: 30px;
    }
  }
  p {
    margin-top: 1.5em;
    line-height: 30px;
    font-size: 24px;
    margin-bottom: 2.5em;
    @media (max-width: 800px) {
      margin-bottom: 1.5em;
      margin-top: 1em;
      font-size: 20px;
    }
  }
`;

const JobButton = styled(Button)`
  max-width: 180px;
  height: 2.75em;
`;
