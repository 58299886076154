import React from "react";
import styled from "@emotion/styled";

const Input = ({ value, type, onEnter, ...others }) => (
  <input
    {...others}
    value={value ? value : ""}
    type={type ? type : "text"}
    onKeyPress={({ key }) => {
      if (key === "Enter" && onEnter) onEnter();
    }}
  />
);

export const InlineInput = styled(Input)`
  display: block;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 14px;
  outline: none;
  border: none;
  border-radius: 9px;
  margin-top: 0.5em;
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.8em 1em;
  width: 100%;
  transition: all ease-in-out 0.1s;

  @media (max-width: 800px) {
    font-size: 14px;
  }

  // Turn off number input increment arrows
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const OutlineInput = styled(InlineInput)`
  &:focus,
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
`;

export const UnderlineInput = styled(InlineInput)`
  &:focus {
    border-bottom: 3px solid var(--primary);
    margin-bottom: calc(1em - 2px);
  }
`;
