import { Button } from "../../../../3-utils/Buttons";
import { Link } from "../../../../0-base/routing";
import React from "react";
import styled from "@emotion/styled";

export function RecruiterRedirect() {
  return (
    <RecruiterRedirectFloater>
      <h1>Want to test ML models in biology?</h1>

      <Link to={"/hire"} resetScroll>
        {isMobile() ? (
          <div>Go to BioMLTasks</div>
        ) : (
          <Button>Go to BioMLTasks</Button>
        )}
      </Link>
    </RecruiterRedirectFloater>
  );
}

const RecruiterRedirectFloater = styled.div`
  background-color: var(--primary);
  position: absolute;
  top: 0;
  left: 5em;
  transform: translate(0, -50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: avenir, nunito;
  padding: 1.5em 3em;
  border: 2px solid rgba(0, 0, 0, 0.2);
  @media (max-width: 800px) {
    padding: 1.5em 2em;
    padding-top: 0.5em;
  }
  z-index: 1;
  > h1 {
    font-weight: lighter;
    color: white;
    display: block;
    font-size: 24px;
    margin-right: 1em;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
`;

function isMobile() {
  return window.innerWidth <= 800;
}
