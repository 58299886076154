import {
  BROWSER_CHANGED_URL,
  UPDATE_ROUTING_PARAMS,
  REQUESTED_NAVIGATE_TO,
  PUSH_REDIRECT,
  POP_REDIRECT
} from "./types";
import ReactGA from "react-ga";
import { getUserRole } from "../../../backend";

// Send the initial pageview
ReactGA.pageview(window.location.pathname);

export const doBrowserChangedUrl = pathname => ({
  type: BROWSER_CHANGED_URL,
  payload: { pathname }
});

export const doUpdateRoutingParams = params => ({
  type: UPDATE_ROUTING_PARAMS,
  payload: { params }
});

export const doNavigateTo = (pathname, options) => {
  console.log("donavigateto options", options);
  const { replace, resetScroll, noPageView } = options ? options : {};
  return dispatch => {
    if (pathname === window.location.pathname + window.location.search) return;

    dispatch({
      type: REQUESTED_NAVIGATE_TO,
      payload: { pathname, replace }
    });

    if (!noPageView) ReactGA.pageview(pathname);

    if (replace) {
      window.history.replaceState(null, "", pathname);
    } else {
      window.history.pushState(null, "", pathname);
    }
    if (resetScroll) window.scrollTo(0, 0);
  };
};

export const doAddRedirect = url => ({ type: PUSH_REDIRECT, payload: url });

export const doPopRedirect = num => ({
  type: POP_REDIRECT,
  payload: typeof num === "number" ? num : 1
});

export const doRedirect = () => async (dispatch, getState) => {
  const { routing } = getState();
  const userRole = getUserRole();
  const { redirectStack } = routing;
  let redirectUrl;
  if (redirectStack.length === 0) {
    // Default redirect
    if (userRole === "new" || userRole == null) {
      // redirectUrl = "/onboarding";
      redirectUrl = "/faculty-search?searchString=biology&universityIds=1";
    } else {
      redirectUrl = "/faculty-search?searchString=biology&universityIds=1";
    }
  } else {
    redirectUrl = redirectStack[redirectStack.length];
    dispatch({ type: POP_REDIRECT });
    if (redirectUrl == null) return;
  }
  dispatch(doNavigateTo(redirectUrl));
};
