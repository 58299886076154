import React from "react";
import styled from "@emotion/styled";
import NavBar from "./NavBar";
import { connect } from "react-redux";

const select = ({ auth }) => ({
  isLoggedIn: auth.user.isLoggedIn,
  adminStatus: auth.user.isAdmin,
  userId: auth.user.userId
});

function NavBarContainer({ isLoggedIn, adminStatus, userId }) {
  let homePageUrl = getHomePageUrl(isLoggedIn, userId);

  let navItems = isLoggedIn
    ? getLoggedInNavItems(adminStatus, homePageUrl)
    : getLoggedOutNavItems();

  return (
    <>
      <NavBar items={navItems} homePageUrl={homePageUrl} />
      <SpacerTheSizeOfTheNavBar />
    </>
  );
}

export default connect(
  select,
  null
)(NavBarContainer);

const SpacerTheSizeOfTheNavBar = styled.div`
  width: 100%;
  height: var(--nav-total-height);
`;

function getHomePageUrl(/*isLoggedIn, userId*/) {
  const defaultHomePage = "/";
  // if (isLoggedIn) return `/student-profile/${userId}`;
  return defaultHomePage;
}

function getLoggedInNavItems(adminStatus /*, homePageUrl*/) {
  const navItems = [];
  // navItems.push({ href: homePageUrl, text: "Profile" });
  // if (isStudent())
  navItems.push({ href: "/project-search", text: "REU Search" });
  navItems.push({ href: "/faculty-search", text: "Faculty Search" });
  // if (isStudent())
  navItems.push({ href: "/dashboard-2", text: "Bookmarks" });
  if (adminStatus) navItems.push({ href: "/admin-tools", text: "Admin Tools" });
  navItems.push({ href: "/settings", text: "Settings" });
  navItems.push({ href: "/logout", text: "Logout" });
  return navItems;
}

function getLoggedOutNavItems() {
  const navItems = [];

  // navItems.push({ href: "/faculty-search", text: "Faculty Search" });
  navItems.push({ href: "/about", text: "About" });
  // navItems.push({ href: '/join', text: 'Join The Team' })
  // navItems.push({ href: "/account/login", text: "Login" });
  // navItems.push({ href: "/account/signup", text: "Sign Up" });
  return navItems;
}
