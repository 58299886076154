import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { LabelledInput } from "../../../3-utils/Inputs";
import { CreatePassword } from "../../auth/CreatePassword";
import { RoundButton } from "../../../3-utils/Buttons";
import { doPasswordSignup, doClearAuthError } from "../../auth/ducks/actions";
import { ReCaptchaSnippet } from "./ReCaptchaSnippet";

const actions = {
  passwordSignup: doPasswordSignup,
  clearErrors: doClearAuthError
};

const select = ({ auth }) => ({
  authError: auth.user.authError
});

function SignUp({ passwordSignup, authError, clearErrors }) {
  useEffect(() => {
    clearErrors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [getPassword, setGetPassword] = useState(null);
  const [submitted, setSubbmited] = useState(false);

  const handleSubmit = () => {
    setSubbmited(true);
    if (!getPassword) return;
    const password = getPassword();
    if (!password || !name || !email) return;
    passwordSignup(email, name, password);
  };

  return (
    <Container>
      {authError && <ErrorMessage>{authError}</ErrorMessage>}
      <LoginForm>
        <LabelledInput
          label="Name"
          error={submitted && !name}
          errorMsg="Name is required"
          value={name}
          placeholder="Name"
          onChange={e => setName(e.target.value)}
        />
        <LabelledInput
          label="Email"
          error={submitted && !email}
          errorMsg="Email is required"
          value={email}
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
        />
        <CreatePassword registerCallback={func => setGetPassword(() => func)} />
        <SubmitButton onClick={handleSubmit}>Sign up!</SubmitButton>
        <ReCaptchaSnippet />
      </LoginForm>
    </Container>
  );
}

export default connect(
  select,
  actions
)(SignUp);

const Container = styled.div`
  text-align: center;
`;

const LoginForm = styled.div`
  text-align: left;
`;

const SubmitButton = styled(RoundButton)`
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1em;
`;
