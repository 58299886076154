import React from "react";
import styled from "@emotion/styled";
import { Link } from "../../../0-base/routing";

export default function DesktopNav({ items }) {
  return (
    <Container>
      {items.map(({ href, text, important }) => (
        <NavItem to={href} key={href} important={important}>
          {text}
        </NavItem>
      ))}
    </Container>
  );
}

const Container = styled.div``;

const NavItem = styled(Link)`
  line-height: var(--nav-height);
  text-decoration: none;
  font-size: 16px;
  border-radius: 50px;
  transition: all 0.15s;
  background-color: ${({ important }) =>
    important ? "var(--primary)" : "transparent"};
  color: ${({ important }) => (important ? "white" : "rgba(0, 0, 0, 0.5)")};
  margin-left: ${({ important }) => (important ? "1em" : "0")};
  padding: ${({ important }) => (important ? "0.5em 1em" : "0.5em")};
  &:hover {
    color: var(--primary);
  }
`;
