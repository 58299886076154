import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { doCloseErrorModal } from "./ducks";

const select = ({ errorModal }) => ({
  message: errorModal.message
});

const actions = {
  onClose: doCloseErrorModal
};

function ErrorModal({ message, onClose }) {
  return (
    <Container show={message}>
      <Content>
        <Header>Oops ...</Header>

        <Message>{message}</Message>

        <Row>
          <Button onClick={onClose}>got it</Button>
        </Row>
      </Content>
    </Container>
  );
}

export default connect(
  select,
  actions
)(ErrorModal);

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ show }) => (show ? "flex" : "none")};
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: animateContainer 0.2s ease forwards;

  @keyframes animateContainer {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  width: 600px;
  background: white;
  padding: 3em 3em 5em 3em;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: var(--shadow-1);
  color: var(--primary);
  cursor: auto;
  border: 2px solid var(--primary);
`;

const Header = styled.h1`
  font-weight: bold;
`;

const Message = styled.h2``;

const Row = styled.div`
  display: flex;
  margin-top: 3em;
`;

const Button = styled.div`
  padding: 0.5em 1em;
  margin-right: 1em;
  color: white;
  background-color: var(--primary);
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: var(--shadow-1);
  border: 1px solid var(--primary);
  &:hover {
    cursor: pointer;
  }
`;
