import React, { useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { LabelledInput } from "../../3-utils/Inputs";
import PropTypes from "prop-types";

// 8 or more characters, upper & lowercase letters, at least one number
const requirement = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$");
const atLeast8 = new RegExp("^(.){8,}$");
const upperAndLower = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{1,}$");
const atLeastANumber = new RegExp("^(?=.*\\d).{1,}$");

// onSubmit is optional, if you want to trigger submit when enter is pressed on the password inputs
export function CreatePassword({ nolabel, registerCallback, onSubmit }) {
  const [submitted, setSubbmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const getPassword = useCallback(() => {
    console.log("get password called");
    setSubbmitted(true);
    if (password === passwordConfirm && requirement.test(password)) {
      return password;
    }
    return null;
  }, [setSubbmitted, password, passwordConfirm]);

  useEffect(() => {
    console.log("registering callback");
    registerCallback(getPassword);
    console.log("finished registering callback");
  }, [getPassword, registerCallback]);

  return (
    <Container>
      <LabelledInput
        label={nolabel ? undefined : "New Password"}
        error={submitted && !password}
        errorMsg="New password is required"
        placeholder="New Password"
        type="password"
        value={password}
        onChange={e => {
          setPassword(e.target.value);
        }}
        onEnter={onSubmit}
      />
      <LabelledInput
        label={nolabel ? undefined : "Confirm Password"}
        error={submitted && !passwordConfirm}
        errorMsg="Confirm password is required"
        placeholder="Confirm Password"
        type="password"
        value={passwordConfirm}
        onChange={e => {
          setPasswordConfirm(e.target.value);
        }}
        onEnter={onSubmit}
      />
      <RequirementsContainer>
        <Requirement pass={atLeast8.test(password)}>
          ✔ At least 8 characters
        </Requirement>
        <Requirement pass={upperAndLower.test(password)}>
          ✔ Upper & lowercase letters
        </Requirement>
        <Requirement pass={atLeastANumber.test(password)}>
          ✔ At least one number
        </Requirement>
        <Requirement pass={password === passwordConfirm}>
          ✔ Passwords match
        </Requirement>
      </RequirementsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const RequirementsContainer = styled.div`
  margin: 0 0 1em 0;
  color: var(--gray-text-on-white);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Requirement = styled.div`
  ${props => props.pass && "color: green;"}
  margin: 0 0.5em;
`;

CreatePassword.propTypes = {
  registerCallback: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};
