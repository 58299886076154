import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import GoogleSignin from "./GoogleSignin";
import MicrosoftSignIn from "./MicrosoftSignIn";

const select = ({ auth }) => ({
  authFailedMessage: auth.user.thirdPartyAuthFailed
});

function ThirdPartySignIns({ logInOnly, authFailedMessage }) {
  return (
    <SignInButtonContainer>
      <GoogleSignin
        logInOnly={logInOnly}
        style={signInBtnStyle}
        btnText="Google"
      />
      <MicrosoftSignIn
        logInOnly={logInOnly}
        style={signInBtnStyle}
        btnText="Microsoft"
      />
      {authFailedMessage && <ErrorMessage>{authFailedMessage}</ErrorMessage>}
    </SignInButtonContainer>
  );
}

export default connect(
  select,
  null
)(ThirdPartySignIns);

const SignInButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-contents: center;
`;

const signInBtnStyle = {
  flex: "1 1 40%",
  margin: "1em 1em"
};

const ErrorMessage = styled.div`
  color: red;
  margin: -0.5em 1em 0 1em;
`;
