import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

export function RadioInput({ id, checked, label, onChange, ...props }) {
  return (
    <Container {...props}>
      <InnerInput
        id={id}
        type="radio"
        checked={checked}
        onChange={onChange ? () => onChange(id) : null}
      />
      <Label htmlFor={id}>{label}</Label>
    </Container>
  );
}

const Container = styled.div``;

const Label = styled.label``;

const InnerInput = styled.input``;

RadioInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func
};
