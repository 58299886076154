import { AboutThera } from "./AboutThera";
import HomeSearch from "./HomeSearch";
import { KeyBenefits } from "./KeyBenefits";
import React from "react";
import { WhyThera } from "./WhyThera";
import styled from "@emotion/styled";

function Home() {
  return (
    <Container>
      <HomeSearch />
      <WhyThera />
      <KeyBenefits />
      <AboutThera />
    </Container>
  );
}

export default Home;

const Container = styled.div`
  min-height: 100vh;
`;
