import {
  AUTH_LOGGED_IN,
  AUTH_LOGGED_OUT,
  AUTH_FAILED,
  STARTED_VERIFY_EMAIL,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  REQUESTED_VERIFICATION_EMAIL,
  STARTED_PASSWORD_RESET,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  THIRD_PARTY_AUTH_FAILED,
  STARTED_LOAD_ACCOUNTS,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_ERROR,
  CLEAR_AUTH_ERROR_MESSAGE,
  LINK_ACCOUNT_ERROR
} from "./types";

export const initialState = {
  user: {
    userId: "",
    isLoggedIn: false,
    isAdmin: false,
    authError: "",
    thirdPartyAuthFailed: "",
    emails: []
  },

  emailVerification: {
    isVerifying: false,
    error: false,
    success: false
  },

  passwordReset: {
    isVerifying: false,
    error: false,
    success: false
  },

  connectedAccounts: {
    isLoading: false,
    accounts: [],
    error: ""
  }
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_LOGGED_IN:
      console.log("auth logged in, payload: ", payload);
      return {
        ...state,
        user: {
          ...state.user,
          userId: payload.userId,
          isLoggedIn: payload.isLoggedIn,
          authError: "",
          thirdPartyAuthFailed: "",
          isAdmin: payload.isAdmin,
          emails: payload.emails
        }
      };
    case AUTH_LOGGED_OUT:
      return initialState;
    case AUTH_FAILED:
      return {
        ...state,
        user: { ...state.user, authError: payload }
      };
    case THIRD_PARTY_AUTH_FAILED:
      return {
        ...state,
        user: { ...state.user, thirdPartyAuthFailed: payload }
      };
    case CLEAR_AUTH_ERROR_MESSAGE:
      return {
        ...state,
        user: { ...state.user, authError: "", thirdPartyAuthFailed: "" }
      };

    case REQUESTED_VERIFICATION_EMAIL:
      return state;
    case STARTED_VERIFY_EMAIL:
      return {
        ...state,
        emailVerification: { ...state.emailVerification, isVerifying: true }
      };
    case EMAIL_VERIFICATION_SUCCESS: {
      let emailVerification = {
        ...state.emailVerification,
        isVerifying: false,
        success: true
      };
      return {
        ...state,
        emailVerification
      };
    }
    case EMAIL_VERIFICATION_ERROR: {
      let emailVerification = {
        ...state.emailVerification,
        isVerifying: false,
        error: true
      };
      return {
        ...state,
        emailVerification
      };
    }

    case STARTED_PASSWORD_RESET:
      return {
        ...state,
        passwordReset: { ...state.passwordReset, isVerifying: true }
      };
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isVerifying: false,
          success: true
        }
      };
    }
    case RESET_PASSWORD_ERROR: {
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isVerifying: false,
          error: true
        }
      };
    }

    case STARTED_LOAD_ACCOUNTS: {
      return {
        ...state,
        connectedAccounts: {
          ...state.connectedAccounts,
          isLoading: true,
          error: ""
        }
      };
    }
    case LOAD_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        connectedAccounts: {
          isLoading: false,
          accounts: payload,
          error: ""
        }
      };
    }
    case LOAD_ACCOUNTS_ERROR: {
      return {
        ...state,
        connectedAccounts: {
          ...state.connectedAccounts,
          isLoading: false
        }
      };
    }
    case LINK_ACCOUNT_ERROR: {
      return {
        ...state,
        connectedAccounts: {
          ...state.connectedAccounts,
          error: payload
        }
      };
    }

    default:
      return state;
  }
}
