import {
  ACTIVATED_ERROR_MODAL,
  CLOSED_ERROR_MODAL
} from "./types";

const initialState = {
  message: ""
};

export default function errorModalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATED_ERROR_MODAL: {
      return {
        ...state,
        message: payload.message
      };
    }

    case CLOSED_ERROR_MODAL: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
