/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Component } from "react";
import PropTypes from "prop-types";
import Floater from "../../1-layouts/Floater";
import { CloseIcon } from "../Icons";

export default class Modal extends Component {
  render() {
    let { title, children, showModal, closeModal } = this.props;
    if (!showModal) return null;

    return (
      <Container onClick={closeModal}>
        <Floater>
          <Content onClick={e => e.stopPropagation()}>
            <CloseContainer>
              <CloseIcon onClick={closeModal} />
            </CloseContainer>

            {title && <Header>{title}</Header>}
            <Body>{children}</Body>
            {/* <Footer {...this.props} handleClose={closeModal} /> */}
          </Content>
        </Floater>
      </Container>
    );
  }
}

Modal.propTypes = {
  // text for the submit button
  submitText: PropTypes.string,
  // what to do when submitted
  onSubmit: PropTypes.func,
  // what goes inside the modal container
  children: PropTypes.object.isRequired,
  // title of the modal
  title: PropTypes.string,
  // boolean for whether to show the modal
  showModal: PropTypes.bool.isRequired,
  // func that should flip the showModal bool
  closeModal: PropTypes.func.isRequired
};

const Content = styled.div`
  width: 700px;
  @media (max-width: 800px) {
    width: calc(100vw - 4em);
    border-radius: 0px;
  }
  max-height: calc(85vh - var(--nav-total-height));
  overflow: scroll;
  background-color: white;
  padding: 1em 2em;
  border-radius: var(--card-border-radius);
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 0em;
  right: 0em;
  padding-right: 0.1em;
  font-size: 2em;
  > div,
  span {
    margin: 0;
  }
`;

const Header = styled.h1`
  // grid-area: header;
  color: slategrey;
  margin-top: 0;
`;

const Body = styled.div`
  // grid-area: body;
  overflow: "scroll";
`;

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: var(--nav-total-height);
  left: 0;
  z-index: 20;
  height: calc(100vh - var(--nav-total-height));
  width: 100vw;
  transition: 0.2s ease;
  overflow: hidden;
`;
