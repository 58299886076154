import React from "react";
import styled from "@emotion/styled";

export function LoginContent() {
  return (
    <Container>
      <Image src="/img/login.svg" />
    </Container>
  );
}

const Container = styled.div``;

const Image = styled.img`
  width: 40vw;
`;
