import styled from "@emotion/styled";

/* 
  Floats a single panel right in the middle of the nearest relative parent 
*/
const Floater = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default Floater;
