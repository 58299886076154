import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

export default function SignInButton({
  onClick,
  iconSrc,
  iconAlt,
  btnText,
  ...props
}) {
  return (
    <ContainerDiv onClick={onClick} {...props}>
      <IconContainer>
        <IconDiv>
          <img alt={iconAlt} height="18px" width="18px" src={iconSrc} />
        </IconDiv>
      </IconContainer>
      <ButtonText>{btnText}</ButtonText>
    </ContainerDiv>
  );
}

SignInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconSrc: PropTypes.string.isRequired,
  iconAlt: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired
};

const height = 40;
export const ContainerDiv = styled.div`
  display: block;
  box-sizing: border-box;
  height: ${height};
  text-align: center;
  min-width: 120px;

  vertical-align: middle;
  background-color: white;
  border: solid 1px #e5e5e5;
  border-radius: 4px;
  box-shadow: var(--card-shadow);
  cursor: pointer;

  transition: all ease-in-out 0.2s;
  &:hover {
    box-shadow: var(--card-shadow-hover);
  }
`;

const IconWidth = 18;
export const IconDiv = styled.div`
  display: block;
  overflow: hidden;
  width: ${IconWidth}px;
  height: ${IconWidth}px;
`;

const IconPadding = (height - IconWidth) / 2;
export const IconContainer = styled.div`
  display: block;
  float: left;
  padding: ${IconPadding}px;
`;

export const ButtonText = styled.span`
  line-height: ${height}px;
  color: #606060;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 16px;
  padding-right: 8px;
`;
