import React, { Component } from "react";

import { Mission } from "./Mission";
// import Team from "./team/Team";
import styled from "@emotion/styled";

export default class About extends Component {
  render() {
    return (
      <Container>
        <Page>
          <Mission />
          <Section>
            <SectionContent>
              <TextWrapper>
                <SectionTitle>About us</SectionTitle>
                <SectionDescription>
                  At Thera, we envision a future where scientific discovery is a harmonious collaboration between humans and AI.
                  Our mission is to revolutionize the scientific process by integrating AI into the very fabric of research,
                  rather than simply augmenting existing workflows with AI tools.
                </SectionDescription>
              </TextWrapper>
            </SectionContent>
          </Section>
          <Section style={{ backgroundColor: "transparent" }}>
            <SectionContent>
              <TextWrapper>
                <SectionTitle>Our story</SectionTitle>
                <SectionDescription>
                  At the intersection of biology and machine learning, a gap emerges -
                  a divide in how each field approaches and interprets data.
                  Traditional biological science is rooted in finding describable insights,
                  a constant pursuit of clarity and explanation in every experimental outcome.
                  Machine learning, on the other hand, treats data as an abstract entity, a maze of metadata,
                  distributions, and statistical nuances, often disconnected from the underlying meaning.
                </SectionDescription>
                <br />
                <SectionDescription>
                  In recent years, life sciences have witnessed an increasing shift towards agnostic data generation.
                  With the basic biological building blocks well-understood, discovery in life sciences is evolving into a form of systems engineering.
                  ‘Hypothesis free’ research, large-scale data generation, and tool and protocol development are becoming the new norms of high-profile labs and consortia.
                </SectionDescription>
              </TextWrapper>
              <Image src="/img/lock.svg" />
            </SectionContent>
          </Section>
          <Section style={{ backgroundColor: "transparent" }}>
            <SectionContent>
              <Image src="/img/magnification.svg" style={{ width: "200px" }} />
              <TextWrapper>
                <SectionTitle>&nbsp;&nbsp;&nbsp;</SectionTitle>
                <SectionDescription>
                  Yet, the heart of biological inquiry still yearns for the explainable,
                  particularly in practical applications such as drug development.
                  We see a dynamic interplay, an accordion-like rhythm of perspectives on data,
                  oscillating between systems discovery and molecular development.
                </SectionDescription>
                <br />
                <SectionDescription>
                  At Thera, we recognize this divide and aim to bridge it.
                  We believe there is a middle ground, where data can be more than just abstract patterns
                  and experimental outcomes, more than an assembly of variables.
                  By bringing AI and biology together, we aspire to create a platform where data
                  is not just consumed but understood, where insights do not just answer questions but
                  inspire new ones. Our journey began with this understanding, and our mission is
                  to shape a future where machine learning and biological sciences exist in harmony,
                  each enhancing the other to drive scientific discovery forward.
                </SectionDescription>
              </TextWrapper>
            </SectionContent>
          </Section>
          <Section style={{ backgroundColor: "var(--bright-blue)" }}>
            {/*<SquareButton>Get Started For Free</SquareButton>*/}
          </Section>
          <Footer src="/img/about-bottom.png" />
        </Page>
      </Container>
    );
  }
}

const Container = styled.div`
  display: block;
  color: var(--black-text-on-white);
  background-color: white;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  box-sizing: border-box;
`;

const Section = styled.div`
  margin: 24px 0;
  background: #f5f5f8;
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 72px 0;
`;

const FlexRow = styled.div`
  display: flex;
`;

const SectionTitle = styled.div`
  font-size: 36px;
  color: var(--bright-blue);
  position: relative;
  margin-bottom: 30px;
  &:after {
    content: "";
    height: 4px;
    width: 50px;
    background: var(--bright-blue);
    position: absolute;
    bottom: -4px;
    left: 0;
  }
`;

const SectionDescription = styled.div`
  font-size: 24px;
`;

const Image = styled.img`
  width: 350px;
  /* height: 300px; */
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 8px;
`;

const TextWrapper = styled.div`
  padding: 12px;
  margin: 0 48px;
`;

const SectionContent = styled(FlexRow)`
  max-width: 1000px;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.img`
  width: 100vw;
`;
