/** @jsx jsx */
import styled from "@emotion/styled";

/*
  If wrapped around other inputs, converts them to evenly measured inputs on same line
  Without InputRow, each input is display block
  Max two Items
  Optional
*/
export const InputRow = styled.div`
  @media (min-width: 800px) {
    display: flex;
    justify-content: space-between;
    .input {
      display: inline-block;
      width: calc(50% - 1em);
    }
  }
`;
