import React, { useState } from "react";
import styled from "@emotion/styled";

const data = [
  {
    quote:
      "The Thera platform helped us deploy ML models in an instant.",
    name: "Ormond",
    position: "Professor at the University of Michigan Ann Arbor",
    imgSrc: "/img/login.svg"
  },
  {
    quote:
      "The Thera platform helped us code in an instant.",
    name: "Julie",
    position: "Biologist at Genentech",
    imgSrc: "/img/login.svg"
  },
  {
    quote:
      "The Thera platform helped us do other stuff in an instant.",
    name: "Santiago",
    position: "Director of STEP Program",
    imgSrc: "/img/login.svg"
  }
];

export function Testimonials() {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const currentQuote = data[selectedIdx];

  return (
    <Container>
      <h1>Why scientists love Thera</h1>
      <InnerContainer>
        <Left>
          {data.map((d, idx) => (
            <Image
              key={idx}
              style={{ backgroundImage: `url("${d.imgSrc}")` }}
              onClick={() => setSelectedIdx(idx)}
            />
          ))}
        </Left>
        <Right>
          <Quote>{'"' + currentQuote.quote + '"'}</Quote>
          <Name>{currentQuote.name}</Name>
          <Position>{currentQuote.position}</Position>
        </Right>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 4em 4em;
  background-color: #ffc655;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const Left = styled.div`
  flex: 0 1 40%;
  min-width: 400px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 4em;
  margin-bottom: 3em;
`;

const imgHeight = "100px";
const Image = styled.div`
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 4px solid white;
  box-shadow: var(--shadow-2);
  cursor: pointer;
  height: ${imgHeight};
  flex: 0 0 ${imgHeight};
  margin: 0 0.5em;
`;

const Right = styled.div`
  flex: 0 1 40%;
  min-width: 400px;
  font-size: 20px;
`;

const Quote = styled.div``;

const Name = styled.div`
  margin-top: 1.5em;
  font-weight: bold;
`;

const Position = styled.div`
  font-size: 14px;
`;
