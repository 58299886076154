import { Link } from "../../../0-base/routing";
import Login from "./Login";
import { LoginContent } from "./LoginContent";
import React from "react";
import SignUp from "./SignUp";
import ThirdPartySignIns from "../../auth/ThirdPartySignIns";
import styled from "@emotion/styled";

function LoginPage({ match }) {
  console.log("match", match);
  const signInPage = match.params.path;

  return (
    <Container>
      <Left>
        <LoginContent />
      </Left>
      <Right>
        <LoginContainer>
          <Chooser signInPage={signInPage} />
          {signInPage === "login" && (
            <div>
              <ThirdPartyHeader>Sign in with</ThirdPartyHeader>
              <ThirdPartySignIns />
              <SignInContent>
                <Divider>
                  <DividerText>or</DividerText>
                </Divider>
                <Login />
              </SignInContent>
            </div>
          )}
          {signInPage === "signup" && (
            <div>
              <ThirdPartyHeader>Sign up with</ThirdPartyHeader>
              <ThirdPartySignIns />
              <SignInContent>
                <Divider>
                  <DividerText>or</DividerText>
                </Divider>
                <SignUp />
              </SignInContent>
            </div>
          )}
        </LoginContainer>
      </Right>
    </Container>
  );
}

export default LoginPage;

const Left = styled.div`
  flex: 0 1;
  margin-right: 100px;
`;

const Right = styled.div`
  flex: 0 1 400px;
`;

function Chooser({ signInPage }) {
  return (
    <TabContainer>
      <Tab to="/account/login" isselected={signInPage === "login" ? 1 : 0}>
        Log In
      </Tab>
      <Tab to="/account/signup" isselected={signInPage === "signup" ? 1 : 0}>
        Sign Up
      </Tab>
    </TabContainer>
  );
}

const TabContainer = styled.div`
  display: flex;
`;

const Tab = styled(Link)`
  flex: 1 1 50%;
  text-align: center;
  padding: 0.75em;
  border: var(--card-border);
  background-color: var(--page-bg-color);
  color: var(--gray-text-on-white);

  ${(props) =>
    props.isselected === 1 &&
    `
    background-color: white;
    border-bottom-color: var(--primary);
    color: var(--primary);
    `}
`;

const ThirdPartyHeader = styled.div`
  padding: 1em 1em 0 1em;
`;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
`;

const LoginContainer = styled.div`
  border: var(--card-border);
  border-radius: 16px;
  background-color: white;
`;

const SignInContent = styled.div`
  padding: 1em;
`;

const Divider = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: var(--card-border);
  line-height: 0.1em;
  margin: 1em 0;
`;

const DividerText = styled.span`
  background: white;
  color: var(--gray-text-on-white);
  padding: 0 10px;
`;
