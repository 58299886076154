import React from "react";
import styled from "@emotion/styled";
import { MainGraphic } from "./MainGraphic";
import { KeyBenefits } from "./KeyBenefits";
import { Statistics } from "./Statistics";
import { Testimonials } from "./Testimonials";

export default function RecruiterLandingPage() {
  return (
    <Container>
      <MainGraphic />
      <Statistics />
      <KeyBenefits />
      <Testimonials />
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  overflow: hidden;
`;
