import React from "react";
import styled from "@emotion/styled";

export function SixFeatureLayout({ items }) {
  return (
    <Container>
      {items.map((item, idx) => (
        <Feature key={idx}>
          <Image src={item.imgSrc} />
          <Content>
            <Header>{item.header}</Header>
            <Description>{item.description}</Description>
          </Content>
          {/* <Image style={{ backgroundImage: `url("${item.imgSrc || ""}")` }} />
          <Header>{item.header}</Header>
          <Description>{item.description}</Description> */}
        </Feature>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  position: relative;
  padding: 6em 2em 5em;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 2em 1em 4em 1em;
  }
`;

const Feature = styled.div`
  width: 25%;
  min-width: 250px;
  margin: 1em 3em;
  @media (max-width: 800px) {
    margin: 1em 0;
    width: 100%;
    display: grid;
    grid-template-areas: "icon content";
    grid-template-columns: 50px 1fr;
    padding-right: 1em;
  }
`;

const Content = styled.div`
  grid-area: content;
`;

const imgHeight = "100px";
const Image = styled.img`
  height: 100px;
  object-fit: cover;
  @media (max-width: 800px) {
   height: auto;
   width: 40px;
   grid-area: icon;
  }
  /* background-position: left center;
  background-repeat: no-repeat;
  height: ${imgHeight};
  @media (max-width: 800px) {
    background-position: center center;
  } */
`;

const Header = styled.h2`
  text-transform: lowercase;
  @media (max-width: 800px) {
    margin-top: 0;
    margin-bottom: 0.2em;
    font-size: 16px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  @media (max-width: 800px) {
    font-size: 16px;
  }
`;
