/** @jsx jsx */
import { useEffect, useState } from "react";

import DesktopNav from "./DesktopNav";
import { Link } from "../../../0-base/routing";
import MobileNav from "./MobileNav";
import { jsx } from "@emotion/core";
import { logoUrl } from "../../../3-utils/Icons";
import styled from "@emotion/styled";

export default function NavBar({ items, homePageUrl }) {
  const isMobile = useIsMobileDevice();
  const DeviceDependentNavContent = isMobile ? MobileNav : DesktopNav;
  return (
    <Container>
      <HomeLink to={homePageUrl}>
        <img src={logoUrl} alt="logo" />
        <Logo>thera</Logo>
      </HomeLink>

      <RightAlign>
        <DeviceDependentNavContent items={items} />
      </RightAlign>
    </Container>
  );
}

const Container = styled.nav`
  background-color: white;
  height: var(--nav-height);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  padding-left: 0.75em;
  padding-right: 1em;
  z-index: 100;
  width: 100%;
  border-bottom: var(--nav-border-bottom-width) solid #ddd;
  display: flex;
  flex-direction: row;
`;

const HomeLink = styled(Link)`
  color: white;
  line-height: var(--nav-height);
  padding-left: 0.5em;
  padding-right: 0.5em;
  & img {
    height: 30px;
    margin-right: 0.5em;
  }
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const RightAlign = styled.div`
  margin-left: auto;
`;

const Logo = styled.div`
  color: var(--primary);
  font-size: 30px;
  white-space: nowrap;
  line-height: 50px;
  padding-left: 5px;
  font-weight: bold;
`;

function useIsMobileDevice() {
  const width = useWindowWidth();
  const THRESHOLD_TO_BE_CONSIDERED_MOBILE = 800;
  return width <= THRESHOLD_TO_BE_CONSIDERED_MOBILE;
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  return width;
}
