import React, { useState, useEffect } from "react";
import SignInButton from "./SignInButton";
import { connect } from "react-redux";
import { UserAgentApplication } from "msal";
import { doThirdPartyLogin, doConnectThirdPartyAccount } from "./ducks/actions";

const actions = {
  doLogin: doThirdPartyLogin,
  doLink: doConnectThirdPartyAccount
};

function MicrosoftSignIn({ doLogin, doLink, btnText, connect, ...props }) {
  const [msOauth2, setMsOauth2] = useState(null);

  useEffect(() => {
    setMsOauth2(
      new UserAgentApplication({
        auth: { clientId: process.env.REACT_APP_MS_CLIENT_ID }
      })
    );
  }, []);

  const signIn = () => {
    let loginRequest = { scopes: ["user.read"] };
    msOauth2
      .loginPopup(loginRequest)
      .then(response => {
        localStorage.setItem("tid", response.tenantId);
        const token = response.idToken.rawIdToken;
        if (connect) doLink("Microsoft", token);
        else doLogin("Microsoft", token);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <SignInButton
      {...props}
      onClick={signIn}
      iconAlt="Microsoft Logo"
      iconSrc="/assets/microsoft_icon.svg"
      btnText={btnText}
    />
  );
}

export default connect(
  null,
  actions
)(MicrosoftSignIn);
