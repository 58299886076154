import React, { useState } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import styled from '@emotion/styled';
import { stateToHTML } from 'draft-js-export-html';

export default function MdEditor({onEdit}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const styles = ['BOLD', 'ITALIC', 'UNDERLINE'];
  onEdit = onEdit ? onEdit : () => {};

  const onChange = (editorState) => {
    setEditorState(editorState);
    onEdit(stateToHTML(editorState.getCurrentContent()));
    console.log(stateToHTML(editorState.getCurrentContent()));
  };

  const onClick = (e) => onChange(RichUtils.toggleInlineStyle(editorState, e.target.name));

  return (
    <Wrapper>
      <Toolbar>
        {styles.map(style => {
          return (
            <Button key={style} onClick={onClick} name={style}>
              {style[0]}
            </Button>
          );
        })}
      </Toolbar>
      <Editor editorState={editorState} onChange={onChange} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 250px;
  .DraftEditor-root {
    background-color: #fffef7;
    width: 80%;
    max-width: inherit;
    margin: auto;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
    height: 300px;
    overflow-y: scroll;
    //overflow-x: scroll;
    padding: 20px;
    font-size: 18px;
    font-family: "calibri", sans-serif;
  }
`;

const Button = styled.button`
  color: grey;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin: 1em;
  margin-bottom: 0em;
  border-top: solid 1px grey;
`;
