import React, { useEffect } from "react";

import ErrorModal from "../../../../errorModal";
import Search from "./Search";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = () => ({});

function HomeSearch() {

  return (
    <Container>
      <SearchWrapper>
        <Search />
      </SearchWrapper>

      <GraphicWrapper>
        <Graphic src="/img/figure.svg" />
      </GraphicWrapper>
      <ErrorModal />
    </Container>
  );
}

export default connect(select)(HomeSearch);

const Container = styled.div`
  min-height: 600px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SearchWrapper = styled.div`
  padding: 2em;
  width: 100%;
  box-sizing: border-box;
  max-width: 1200px;

  @media (min-width: 800px) {
    padding-left: 4em;
    padding-top: 0em;
  }
  z-index: 5;
`;

const GraphicWrapper = styled.div`
  grid-area: graphic;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;

  @media (max-width: 800px) {
    align-items: center;
    height: 200px;
  }
`;

const Graphic = styled.img`
  height: 600px;
  width: 700px;
  margin-left: 30px;
  @media (max-width: 800px) {
    height: 400px;
  }
  margin-bottom: -80px;
`;

const Image = styled.img`
  width: 50px;
  position: absolute;
`;
