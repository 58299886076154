import React from "react";
import styled from "@emotion/styled";

const data = [
  {
    header: "200+",
    description: "Biology models"
  },
  {
    header: "1000+",
    description: "Model runs"
  },
  {
    header: "2000+",
    description: "Models trained"
  }
];

export function Statistics() {
  return (
    <Container>

      {data.map((item, idx) => (
        <ItemContainer key={idx}>
          <Image style={{ backgroundImage: `url("${item.imgSrc || ""}")` }} />
          <Header>{item.header}</Header>
          <Description>{item.description}</Description>
        </ItemContainer>
      ))}
    </Container>

  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  background-color: #f6f6f8;
  justify-content: center;
  padding: 3em;
`;

const ItemContainer = styled.div`
  width: 25%;
  min-width: 250px;
  margin: .5em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.div`
`;

const Header = styled.h2`
  font-size: 2em; /* Adjust font-size as needed */
`;

const Description = styled.div`
  font-size: 1.5em; /* Adjust font-size as needed */
`;