import React from "react";
import styled from "@emotion/styled";
import { Label2 } from "./Input";

export function TextArea({ label, boldlabel, ...props }) {
  return (
    <div>
      {label && <Label2 bold={boldlabel}>{label}</Label2>}
      <StyledTextArea {...props} />
    </div>
  );
}

const StyledTextArea = styled.textarea`
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  outline: none;
  font-size: var(--default-font-size);
  font-family: var(--default-font-family);
  // box-sizing: border-box;

  padding: 0.6em 0.7em;
  width: calc(100% - 1.4em - 2px);
  min-height: 8.2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  &:focus {
    border-color: var(--primary);
  }
`;
