import { ACTIVATED_ERROR_MODAL, CLOSED_ERROR_MODAL } from "./types";

export function doActivateErrorModal(message) {
  return {
    type: ACTIVATED_ERROR_MODAL,
    payload: {
      message
    }
  };
}

export function doCloseErrorModal() {
  return {
    type: CLOSED_ERROR_MODAL
  };
}