import React from "react";
import styled from "@emotion/styled";

export function CheckboxInput({
  onClick,
  children,
  checked,
  onChange,
  hasBorder,
  ...otherProps
}) {
  return (
    <Label>
      <Checkbox
        type="checkbox"
        onClick={onClick}
        checked={checked}
        onChange={onChange ? onChange : () => { }}
      />
      <Checkmark hasBorder={hasBorder ? hasBorder : false} />
      <TextDiv {...otherProps}>{children}</TextDiv>
    </Label>
  );
}

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0.5em;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > input:checked + span {
    background-color: var(--primary);
    &:after {
      display: block;
    }
  }
  &:hover input:not(:checked) + span {
    background-color: #ccc;
  }
`;

const Checkbox = styled.input`
  // Hide browser default checkbox
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  ${props => props.hasBorder ? `border: 1px solid black;` : ``}
  background-color: #eee;
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const TextDiv = styled.div`
  font-size: 12px;
`;
