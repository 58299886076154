import { Input, Label2 } from "./Input";

import PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";

export const LabelledInput = ({
  label,
  boldlabel,
  type,
  value,
  error,
  errorMsg,
  onEnter,
  placeholder,
  onChange,
  containerStyle,
  style,
  ...others
}) => {
  if (error) {
    console.log("error", error);
    console.log("errMsg", errorMsg);
  }

  return (
    <Container error={error && errorMsg} {...others} style={containerStyle}>
      {label && <Label2 bold={boldlabel}>{label}</Label2>}
      <Input
        type={type}
        value={value ? value : ""}
        // custom dom attributes must be non-boolean
        iserror={error && errorMsg ? 1 : 0}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={({ key }) => {
          if (key === "Enter" && onEnter) onEnter();
        }}
        style={style}
      />
      {error && errorMsg && <Error>{errorMsg}</Error>}
    </Container>
  );
};

const Error = styled.div`
  color: var(--error-red);
`;

const Container = styled.div`
  box-sizing: border-box;
  // min-height: 6.75em;
  // padding-bottom: ${(props) => (props.error ? "0" : "0.25em")};
  margin-bottom: 1em;
`;

LabelledInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onEnter: PropTypes.func,
};
