import React from "react";

export const ReCaptchaSnippet = () => {
  return (
    <div>
      {"This site is protected by reCAPTCHA and the Google "}
      <a rel="nofollow" href="https://policies.google.com/privacy">
        Privacy Policy
      </a>
      {" and "}
      <a rel="nofollow" href="https://policies.google.com/terms">
        Terms of Service
      </a>{" "}
      apply.
    </div>
  );
};
