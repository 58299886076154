import axios from "axios";
import FormData from "form-data";

export const theraApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
});

theraApi.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
theraApi.defaults.headers.common["Accept"] = "application/json";

export const theraRecaptchaApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
});
theraRecaptchaApi.defaults.headers.common["X-Requested-With"] =
  "XMLHttpRequest";
theraRecaptchaApi.defaults.headers.common["Accept"] = "application/json";

// inject captcha into requests
theraRecaptchaApi.interceptors.request.use(async request => {
  const recaptcha = await getRecaptchaToken(request.url);
  if (request.method === "post") {
    request.data = { ...request.data, recaptcha };
  } else if (request.method === "get") {
    request.params = { ...request.params, recaptcha };
  }
  console.log(request.url, request);
  return request;
});

async function getRecaptchaToken(action) {
  return window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
    action
  });
}

// Filters only work on backend routes which have been
// coded to receive them. Mostly ManyToMany GET requests
//
// Example filters for a GET users request:
// {
//   where: {name: "joe"}
// }
//
// {
//   where: {
//     and: [
//       {name: "joe"},
//       {hasApprovalPower: true}
//     ]
//   }
// }
//
// See Loopback documentation for more filter examples
// a large number of functions and logical operators
// are supported. Even beyond "where" clauses
function appendFilter(path, filter) {
  if (filter != null && filter !== "") {
    return path + "?filter=" + JSON.stringify(filter);
  } else {
    return path;
  }
}

export async function recaptchaGet({ path, filter }) {
  return (
    theraRecaptchaApi
      //.get(appendFilter(path, filter))
      .get(path, { params: { filter: JSON.stringify(filter) } })
      .then(response => {
        return respond({
          status: response.status,
          data: response.data
        });
      })
      .catch(error => {
        return error_handle({ error: error });
      })
  );
}

export async function recaptchaPost({ path, data, filter }) {
  return theraRecaptchaApi
    .post(appendFilter(path, filter), deepNullDelete(data))
    .then(response => {
      return respond({
        status: response.status,
        data: response.data
      });
    })
    .catch(error => {
      return error_handle({ error: error });
    });
}

// Wrappers for simple http requests to reduce code copying, esp. for error handling
export async function simpleGet({ path, filter }) {
  return (
    theraApi
      //.get(appendFilter(path, filter))
      .get(path, { params: { filter: JSON.stringify(filter) } })
      .then(response => {
        return respond({
          status: response.status,
          data: response.data
        });
      })
      .catch(error => {
        return error_handle({ error: error });
      })
  );
}

export async function simpleDelete({ path, data, filter }) {
  return theraApi
    .delete(appendFilter(path, filter), { data: data })
    .then(response => {
      return respond({
        status: response.status,
        data: response.data
      });
    })
    .catch(error => {
      return error_handle({ error: error });
    });
}

export async function simplePatch({ path, data, filter }) {
  return theraApi
    .patch(appendFilter(path, filter), deepNullDelete(data))
    .then(response => {
      return respond({
        status: response.status,
        data: response.data
      });
    })
    .catch(error => {
      return error_handle({ error: error });
    });
}

export async function simplePost({ path, data, filter }) {
  return theraApi
    .post(appendFilter(path, filter), deepNullDelete(data))
    .then(response => {
      return respond({
        status: response.status,
        data: response.data
      });
    })
    .catch(error => {
      return error_handle({ error: error });
    });
}

export async function formDataPost({ path, data }) {
  let formData = new FormData();
  formData.append("file", data);

  return theraApi
    .post(path, formData)
    .then(response => {
      return respond(response.status, response.data);
    })
    .catch(error => {
      return error_handle(error);
    });
}

export async function hasManyDelete({ path, id_array, filter }) {
  return theraApi
    .delete(appendFilter(path, filter), id_array)
    .then(response => {
      return respond({
        status: response.status,
        data: response.data
      });
    })
    .catch(error => {
      return error_handle({ error: error });
    });
}

// Response generator
// Currently redundant, may change later
export function respond({ status, data }) {
  return { status: status, data: data };
}

// Error handling //

export function error_respond(error) {
  return {
    status: error.response.status,
    error: error.response.data.error.message,
    exception: error.response.data.error.exception
  };
}

// 0 is a made up error code for non-server-related issues
export function error_handle(err) {
  console.log(err);
  let error = err.error;
  if (!error) {
    // Network error! Axios's network error is different from
    // all it's other error's
    alert(
      "Could not connect to server. Please check your internet and the status of the Thera server."
    );
    let error = { error: "Network error" };
    return error;
  }
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 503) {
      // 503 = server throttling, alert user
      alert(
        "You have sent too many requests to the server and are being throttled. " +
        "This is intended to combat spam and bots. " +
        "If this has occurred during normal usage of the site, please contact info@thera-ai.com " +
        "and we will fix it."
      );
    }

    // return respond(error.response.status, error.response);
    if (error.response.data.error) throw error_respond(error);
    else throw error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // return respond(0, error.request)
    throw error;
  } else {
    // Something happened in setting up the request that triggered an Error
    // return respond(0, error.message)
    throw error;
  }
}

/* Gets a model WITH all hasMany arrays
 *
 * Currently does not support filters due to complexity
 *
 * Inefficient - makes a request for every hasMany relationship
 * Should be moved to backend, such as the attachPivots helper
 * whenever possible. Unfortunately Loopback
 * does not have an automatic version implemented yet
 */
export async function getModelHasMany({ path, fields }) {
  let promises = [];
  let responseObject = {};
  for (let j = 0; j < fields.length; ++j) {
    promises.push(theraApi.get(path + "/" + fields[j]));
  }
  return theraApi.get(path).then(response => {
    responseObject = response.data;
    return Promise.all(promises).then(responses => {
      for (let i = 0; i < responses.length; ++i) {
        responseObject[fields[i]] = responses[i];
      }
      return responseObject;
    });
  });
}

/* Deep copies object without any null or undefined properties
 * and removes negative ids
 *
 * necessary because Loopback does not accept null properties,
 * nullable objects must be specified or not present
 */
export function deepNullDelete(obj_in) {
  // Deep copy first, doesn't copy functions
  if (!obj_in) return {};
  let obj = JSON.parse(JSON.stringify(obj_in));
  deepNullDeleteHelper(obj);
  return obj;
}

/* Mutates object, make sure it's already been deep copied */
function deepNullDeleteHelper(obj) {
  if (Array.isArray(obj)) {
    for (let el of obj) {
      deepNullDeleteHelper(el);
    }
  } else if (typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (!obj[key]) delete obj[key];
        else if (key === "id" && obj[key] < 0) delete obj[key];
        else if (key.endsWith("Pivots")) delete obj[key];
        deepNullDeleteHelper(obj[key]);
      }
    }
  }
  return obj;
}

// In order to avoid 422 errors, you can use this to delete
// uninitialized hasMany objects from arrays
export function validateArrayRequiredField(arr, fieldname) {
  for (let i = 0; i < arr.length; ++i) {
    console.log(arr, arr.length);
    if (!arr[i][fieldname]) {
      arr.splice(i, 1);
      i--;
    }
  }
}
